import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { getTaskStatusChipConfig } from "app";
import * as d3 from "d3";
import { OrgChart } from "d3-org-chart";

import { useTheme } from "@mui/material";
import { isIOS } from "@sbm/fe-utils";
import { TExecutionTrackerNode } from "@types";

import CornerDownRight from "../../../../../../../assets/images/task-tracker-icons/CornerDownRight.svg";
import FilePlus2 from "../../../../../../../assets/images/task-tracker-icons/FilePlus2.svg";
import { useMedia } from "../../../../../../hooks";
import { getTruncatedStatus } from "../../helpers";

interface OrgChartData {
  data: TExecutionTrackerNode[];
}

const parser = new DOMParser();

export const ExecutionTrackerScheme: React.FC<OrgChartData> = ({ data }) => {
  const theme = useTheme();
  const chart = useRef<OrgChart<TExecutionTrackerNode> | null>(null);
  const d3Container = useRef(null);
  const { t } = useTranslation();
  const { t: taskTranslate } = useTranslation("tasks");

  const iosDetected = isIOS();
  const [{ tabletDown }] = useMedia();

  const isIosMobileOrTablet = iosDetected && tabletDown;

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (!chart.current) {
      chart.current = new OrgChart();
    }

    if (data && d3Container.current) {
      chart.current
        .container(d3Container.current)
        .data(data)
        .nodeHeight((node) => 178)
        .nodeWidth(() => 298 + 2)
        .childrenMargin(() => 100)
        .compactMarginBetween(() => 35)
        .compactMarginPair(() => 30)
        .neighbourMargin(() => 20)
        .layout("left")
        .compact(false)
        .expandAll()
        .linkUpdate(function (this: SVGPathElement) {
          d3.select(this)
            .attr("stroke", () => theme.palette.secondary.main)
            .attr("stroke-width", () => 1)
            .attr("stroke-dasharray", () => "2");
        })
        .nodeContent((node) => {
          const {
            generatedEventText,
            parentId,
            nameOfTypeEvent,
            statusForAuthor,
            statusForExecutor,
          } = node.data;
          const color = "#FFFFFF";
          const imageDiffVert = 10 + 2;
          const parsedHtmlDoc = parser.parseFromString(
            generatedEventText,
            "text/html"
          );
          const htmlDoc = parsedHtmlDoc.getElementsByTagName("body");
          let labelBody = htmlDoc?.[0].innerHTML || "";
          const taskTranslated = t("task");
          const { backgroundColor: authorBackground, color: authorColor } =
            getTaskStatusChipConfig(
              statusForAuthor,
              theme.palette,
              t,
              true,
              true
            );
          const { backgroundColor: executorBackground, color: executorColor } =
            getTaskStatusChipConfig(
              statusForExecutor,
              theme.palette,
              t,
              true,
              false
            );

          // TODO: Change this part after will be done in backend
          labelBody = labelBody
            .replace(new RegExp(taskTranslated, "g"), "")
            .trim();

          return `
              <div style="width:300px;height:${node.height}px;padding-top:${
            imageDiffVert + 5
          }px;padding-left:1px;padding-right:1px">
                <div style="background-color:${color};height:${
            node.height
          }px;box-shadow: ${
            isIosMobileOrTablet ? "none" : theme.shadows[4]
          };border-radius:16px; font-family:'Montserrat';padding:16px">
                  <div style="display:flex;align-items:center;gap:8px">
                    <div style="display:flex">
                    <img src="${parentId ? CornerDownRight : FilePlus2}"/>
                    </div>
                    <div>
                      <div style="font-weight:700;font-size:18px">${labelBody}</div>
                        <p style="font-weight:400;font-size:8px">${nameOfTypeEvent}</p>
                    </div>
                  </div>
                  <div style="display:flex; align-items:center;gap:4px;padding-top: 8px">
                  <div style="display:flex; flex-direction: column;  align-items: flex-start; width: 132px;height:48px">
                  <p style="font-weight:400;font-size:10px; line-height: 14px; text-align: center">${taskTranslate(
                    "author"
                  )}</p>
                    <p style="font-weight:700;font-size:10px;line-height: 14px; text-align: center;padding-bottom:4px">Иванов С.М.</p>
                    <div style="width:80px;height:16px;border-radius:8px;background:${authorBackground}">
                    <p style="display:flex;justify-content:center;align-items:center;color:${authorColor};font-weight:600;font-size:8px;line-height:14px">${getTruncatedStatus(
            taskTranslate(`statuses.${statusForAuthor}`)
          )}</p>
                    </div>
                  </div>
                  <div style="display:flex; flex-direction: column;  align-items: flex-start; width: 132px;height:48px">
                  <p style="font-weight: 400;font-size:10px; line-height: 14px; text-align: center">${taskTranslate(
                    "executor"
                  )}</p>
                    <p style="font-weight: 700;font-size:10px; line-height: 14px; text-align: center;padding-bottom:4px">Петров Н.А.</p>
                    <div style="width:80px;height:16px;border-radius:8px;background:${executorBackground}">
                    <p style="display:flex;justify-content:center;align-items:center;color:${executorColor};font-weight:600;font-size:8px;line-height:14px">${getTruncatedStatus(
            taskTranslate(`statuses.${statusForExecutor}`)
          )}
                    </p>
                    </div>
                  </div>
                  </div>
                  <div style="padding-top:8px">
                  <p style="font-weight:400; font-size:10px;line-height:14px">${t(
                    "contract.content"
                  )}</p>
                  <p style="font-weight:700;font-size:10px;line-height:14px;">Прошу организовать участие и подготовить ответ с параметр...</p>
                  </div>
                </div>
              </div>`;
        })
        .render();
    }

    return () => {
      chart.current = null;
    };
  }, [data, theme, isIosMobileOrTablet, t, taskTranslate]);

  return <div ref={d3Container} />;
};
