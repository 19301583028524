import {
  ContractStatusEnum,
  DocumentTypeOfTheDocumentEnum,
  IEmployee,
  IOrganization,
  IPersonalData,
  StorageOrderEnum,
} from "@types";

export type TParty = {
  organizationId?: number;
  roleContractPartyId?: number;
  personalDataFullName?: string;
  personalDataId?: number;
  externalOrganizationStaffId?: number;
  employeeId?: number;
  externalSubstitutionId?: number;
  legalEntity?: boolean | string;
  id?: number;
  operatesOnBasis?: string;
  contractPartyId?: number;
  // All above used for view mode
  organization?: IOrganization;
  personalData?: IPersonalData;
  typicalRoleContractParty?: { nameTypicalRoleContractParty: string };
};

export enum PaymentTypeEnum {
  advance = "advance",
  postpayment = "postpayment",
}

export interface IPaymentOrderForm {
  prepaymentConditionId?: number;
  postpaymentConditionId?: number;
  percentagePrepaymentFromContractPrice?: number;
  paymentWithoutVat?: number;
  paymentWithVat?: number;
  paymentOrderVatPercentage?: number;
  paymentType: PaymentTypeEnum;
  createdAt?: string;
  updatedAt?: string;
  id?: number;
  validity?: boolean;
  versionContractId?: number;
}

export interface IFrameworkContract {
  dateContract?: string;
  id?: number;
  numberContract?: number | null;
}

export interface IContractForm {
  id?: number;
  attachmentsCount?: number;
  contractCategoryId: string | number;
  contractTypeId: string;
  contractSubtypeId: string;
  parties: TParty[];
  responsibleEmployeeId?: number;
  responsibleExternalSubstitutionId?: number;
  responsibleEmployee?: { id: number; personalData: IPersonalData };
  internalNumber: string;
  constructionComplexIds?: number[];
  numberContract?: string;
  pricingContractId?: string;
  pricingTypeId?: string;
  hasObjectInternalApprovalProcess: boolean;
  dateContract?: Date;
  createdAt?: Date;
  dateExecutionExpiryContract?: Date | string;
  dateExecutionBeginningContract?: Date | string;
  dateValidityBeginningContract?: Date | string;
  dateOfStatusChanging?: Date;
  dateValidityExpiryContract?: Date;
  updatedAt?: Date;
  contractSubtypeTypicalSubjectOfTheContractId: string;
  contractSubjectDetails: string;
  priceContractWithoutVat?: number;
  initiatorOfTheContract?: { employee: IEmployee; id: number };
  initiatorOfTheContractId?: number;
  vatPercentage?: number;
  priceVat?: number;
  priceTotal?: number;
  percentagePrepaymentFromContractPrice?: number;
  prepaymentAmountOfContract?: number;
  advancePaymentWithoutVat?: number;
  paymentOrderVatPercentage?: number;
  advancePaymentVat?: number;
  prepaymentTotal?: number;
  subjectContractAdditionalInformation?: string;
  cfaDepartment?: string;
  contractSupervisor?: string;
  storageOrderForOriginal?: StorageOrderEnum;
  statusContract?: ContractStatusEnum;
  conditionExecutionBeginningContractId: string;
  conditionExecutionExpiryContractId: string;
  conditionValidityExpiryContractId: string;
  conditionValidityBeginningContractId?: string;
  supplementaryAgreementsCount?: number;
  numberOfAppendices?: number;
  frameworkContractId?: string;
  frameworkContract?: IFrameworkContract;
  contractAssignee: undefined;
  paymentOrders: IPaymentOrderForm[];
  constructionComplexes?: { id: number; constructionComplexName: string }[];
  presigned?: boolean;
  dateOfFrameworkContract?: Date;
  creatorEmployeeId?: number;
  agreementAllowed?: boolean;
  responsibleExternalSubstitution?: {
    id: number;
    employee: IEmployee;
  };
  contractDocument?: {
    id: number;
    typeOfTheDocument: DocumentTypeOfTheDocumentEnum;
    shortNameOfTheDocument: string;
    longNameOfTheDocument: string;
  };
}
