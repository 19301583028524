import { TSupAgreementParty } from "../app/containers/sup-agreement/CreateSAContainer/types";
import {
  ContractFileAppointmentEnum,
  FileStatusEnum,
  SupplementaryAgreementFileAppointmentEnum,
} from "./attachments";
import { DocumentTypeOfTheDocumentEnum } from "./messages";
import { IEmployee, IPersonalData } from "./organizations";
import {
  EnumApproverDocumentStatusType,
  EnumApproverStatusType,
  EnumApproverType,
  SingleApprovalOverdueStatusEnum,
} from "./standardRoutes";
import { StatusForAuthorEnum } from "./tasks";

export interface IContractEntity {
  id: number;
  subjectBriefly: string;
  subjectInMoreDetails: string;
  party1OrganizationName: string;
  party1OrganizationId: number;
  party2OrganizationName: string;
  party2PersonalDataFullName: string;
  party2OrganizationId: number;
  party3OrganizationName: string | null;
  party3OrganizationId: number | null;
  party1RoleName: string;
  party1RoleId: number;
  party2RoleName: string;
  party2RoleId: number;
  party3RoleName: string | null;
  party3RoleId: number | null;
  contractNumber: string;
  contractCategoryName: string;
  contractCategoryId: number;
  contractTypeName: string;
  contractTypeId: number;
  contractSubtypeName: string;
  contractSubtypeId: number;
  contractStatus: string;
  constructionComplexName: string;
  constructionComplexId: number;
  dateOfSigning: string;
  expirationDate: string;
  contractTotalPrice: number | string;
  supplementaryAgreementsSigned: number;
  createdAt: string;
  updatedAt: string;
}

export interface IContractFrameEntity {
  id: number | string;
  contractNumber: number | string;
  dateOfSigning: string;
  expirationDate: string;
  party1OrganizationName: string;
  party2OrganizationName: string;
  contractStatus: string;
  category: string;
}

export enum OperatesOnBasisEnum {
  charter = "charter",
  powerOfAttorney = "power_of_attorney",
  notRequired = "not_required",
  order = "order",
  directive = "directive",
}

export enum ContractStatusEnum {
  draft = "draft",
  preparation = "preparation",
  inAgreement = "in_agreement",
  underSigning = "under_signing",
  sentToContractDept = "sent_to_contract_dept",
  inAction = "in_action",
  terminated = "terminated",
  disputed = "disputed",
  canceled = "canceled",
  executed = "executed",
}

export interface ICurrentUserApprovals {
  position: string;
  personalData: IPersonalData | null;
  date: string;
  comment: string;
  result: EnumApproverStatusType;
  iterationNumber: number;
}

export enum TypeOfApprovalRouteEnum {
  typicalRoute = "typical_route",
  customRoute = "custom_route",
}

export interface IAgreementComments {
  objectInternalApprovalProcessId: number;
  longNameOfTheDocument: string;
  typeOfApprovalRoute: TypeOfApprovalRouteEnum;
  numberOfApprovalRoute: null;
  dateApprovalBeginning: string | null;
  factDateApprovalApprovalEnding: string | null;
  statusApprovalProcessInternalObject: EnumApproverDocumentStatusType | null;
  comments: {
    position: string;
    nameOfStructuralUnit: string;
    personalData: IPersonalData;
    date: string;
    comment: string;
    result: EnumApproverStatusType;
    iterationNumber: number;
    typeOfTheCommentAuthor: string;
    approverMission: EnumApproverType;
  }[];
}

export interface IApprovalForHomepage {
  document: string;
  dateReceived: string;
  plannedApprovalDate: string;
  actualApprovalDate: string;
  status: EnumApproverStatusType;
  statusForDocument: EnumApproverStatusType;
  contractId: number;
  singleApprovalOverdueStatus: SingleApprovalOverdueStatusEnum;
  linkedEntity: {
    typeOfTheDocument: DocumentTypeOfTheDocumentEnum;
    messageId: number | null;
    taskId: number | null;
    regularTaskId: number | null;
    contractId: number | null;
    supplementaryAgreementId: number | null;
    correspondenceId: number | null;
    oadId: number | null;
    serviceNoteId: number | null;
    workplanId: number | null;
    minutesInternalMeetingId: number | null;
    powerOfAttorneyId: number | null;
    manualId: number | null;
    externalLinkId: number | null;
  };
}

export enum StorageOrderEnum {
  general = "general",
  special = "special",
}

export interface ISupAgreementForm {
  id?: number;
  contractId?: number;
  typeSupplementaryAgreementId?: number;
  initiatorEmployeeId?: number;
  numberSupplementaryAgreement?: string;
  basisSupplementaryAgreement?: string;
  storageOrderForOriginal?: "common" | "special";
  statusSupplementaryAgreement?: ContractStatusEnum;
  dateOfSigningSupplementaryAgreement?: Date;
  dateOfStatusChanging?: Date;
  briefTextMeaningSupplementaryAgreement?: string;
  fullTextMeaningSupplementaryAgreement?: string;
  numberOfAppendices?: number;
  updatedAt?: Date;
  createdAt?: Date;
  parties?: TSupAgreementParty[];
  responsibleEmployeeId?: number;
  initiatorEmployee?: IEmployee | { title: string; id: number };
  presigned: boolean;
}

export interface IContractHistory {
  id: number;
  taskId: number;
  typeEventId: number;
  statusForAuthor: StatusForAuthorEnum;
  showTableResolution: boolean;
  scheduledDateOfExecution: Date | null;
  createdAt: Date;
  typeEvent: {
    id: number;
    nameOfTypeEvent: string;
    createdAt: Date;
    updatedAt: Date;
  };
  resolutionTo: number | null;
  resolutionFrom: number | null;
  generatedText: string;
  contract: {
    id: number;
    numberContract: number | null;
    dateContract: Date | null;
  };
}

export enum ContractsActionsTypeEnum {
  edit = "edit",
  delete = "delete",
  assignPerson = "assignPerson",
  editStatus = "editStatus",
  editPerson = "editPerson",
  assignNumber = "assignNumber",
  changeResponsiblePerson = "changeResponsiblePerson",
  setUpApproval = "setUpApproval",
}

export interface IResponsiblePersonForm {
  responsibleExternalSubstitutionId?: number | null;
  responsibleEmployeeId: number | null | undefined;
  authorExternalSubstitutionId: number | null;
  authorEmployeeId: number | null;
  textOfTheTask: string;
  personalControl: boolean;
  reportRequired: boolean;
  highPriority: boolean;
  affiliationConstructionComplex?: boolean;
  constructionComplexes: number[];
  scheduleDate: string;
  scheduleTime?: string;
  workingDaysForExecution: number;
  calendarDaysForExecution: number;
}

export interface IUpdateStatus {
  status: ContractStatusEnum;
  comment?: string;
}

export interface IAssignNumber {
  isCounterparty?: string;
  numberContract?: string;
  dateContract?: Date;
}

export interface IGenerateRegistrationNumber {
  organizationId: number;
  frameworkContractId?: number;
}

export interface IAttachmentForm {
  fileAppointment?: ContractFileAppointmentEnum;
  fileStatus?: FileStatusEnum;
  file: null | File;
  documentNumber?: string;
  additionalInformation?: string;
}

export interface ISAAttachmentForm {
  fileAppointment?: SupplementaryAgreementFileAppointmentEnum;
  fileStatus?: FileStatusEnum;
  file: null | File;
  documentNumber?: string;
  additionalInformation?: string;
}
