import { createSlice } from "@reduxjs/toolkit";
import {
  DrawerVariantsEnum,
  DrawerWithDataVariantsEnum,
  IMetrics,
} from "@types";

interface IGlobal {
  loading: boolean;
  breadCrumbOpen: boolean;
  drawer?: DrawerVariantsEnum;
  loadedFilesCount: number;
  drawerType: string;
  metrics?: IMetrics;
  drawerWithData?:
    | {
        variant: DrawerWithDataVariantsEnum;
        content?: any;
      }
    | null
    | undefined;
}

export const initialStateGlobal: IGlobal = {
  loading: false,
  breadCrumbOpen: true,
  drawer: undefined,
  drawerWithData: null,
  metrics: undefined,
  loadedFilesCount: 0,
  drawerType: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState: initialStateGlobal,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setToggleBreadCrumb: (state, action) => {
      state.breadCrumbOpen = action.payload;
    },
    openDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    closeDrawer: (state) => {
      state.drawer = undefined;
    },
    openDrawerWithData: (state, action) => {
      state.drawerWithData = action.payload;
    },
    closeDrawerWithData: (state) => {
      state.drawerWithData = null;
    },
    setLoadedFilesCount: (state, action) => {
      state.loadedFilesCount = state.loadedFilesCount + action.payload;
    },
    setMetrics: (state, action) => {
      state.metrics = action.payload;
    },
  },
});

export const {
  setLoading,
  openDrawer,
  closeDrawer,
  setToggleBreadCrumb,
  openDrawerWithData,
  closeDrawerWithData,
  setLoadedFilesCount,
  setMetrics,
} = globalSlice.actions;

export default globalSlice.reducer;
