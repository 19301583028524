import { axiosService } from "app";

import { getFilterParams } from "@sbm/fe-utils";
import { QueryFunctionContext } from "@tanstack/react-query";
import {
  IAcceptExecution,
  IAttachesTaskLinkBody,
  IRejectExecution,
  IViewTask,
  TasksViewEnum,
} from "@types";

export const fetchTasks = async (
  { pageParam }: QueryFunctionContext,
  type: "incoming" | "outgoing",
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = [],
  path: TasksViewEnum,
  employeeId?: number,
  documentId?: number
) => {
  const page = pageParam ?? 1;
  const limit = path === TasksViewEnum.calendar ? 100 : 20;

  const dateFilters = [
    "filter.createdAt",
    "filter.actualDateOfExecution",
    "filter.scheduledDateOfExecution",
    "filter.documentId",
  ];

  const filterParams = getFilterParams(filter, dateFilters, type);

  // Need to set boolean value, can't be an array
  if ("filter.highPriority" in filterParams) {
    const copy = filterParams["filter.highPriority"];
    filterParams["filter.highPriority"] = copy[0];
  }
  // Need to set boolean value, can't be an array
  if ("filter.overdue" in filterParams) {
    const copy = filterParams["filter.overdue"];
    filterParams["filter.overdue"] = copy[0];
  }
  // Need to set boolean value, can't be an array
  if ("filter.parentTaskId" in filterParams) {
    const copy = filterParams["filter.parentTaskId"];
    filterParams["filter.parentTaskId"] = copy[0];
  }
  // Need to set boolean value, can't be an array
  if ("filter.taskViewedByExecutor" in filterParams) {
    const copy = filterParams["filter.taskViewedByExecutor"];
    filterParams["filter.taskViewedByExecutor"] = copy[0];
  }
  // Need to set boolean value, can't be an array
  if ("filter.neededToBeViewedByAuthor" in filterParams) {
    const copy = filterParams["filter.neededToBeViewedByAuthor"];
    const filteringValue = String(copy[0] === "false");
    filterParams["filter.neededToBeViewedByAuthor"] = filteringValue;
  }

  const params: {
    page: number;
    limit: number;
    search: string;
    sortBy: string;
    "filter.viewMode"?: string;
    employeeId?: number;
    "filter.documentId"?: number;
  } = {
    page,
    limit,
    search,
    sortBy,
    ...filterParams,
  };

  if (path === TasksViewEnum.calendar) {
    params["filter.viewMode"] = path;
  }

  if (employeeId) {
    params["employeeId"] = employeeId;
  }

  if (documentId) {
    params["filter.documentId"] = documentId;
  }

  const endpoint = employeeId ? `/task/${type}/subordinate` : `/task/${type}`;

  const { data } = await axiosService({
    endpoint,
    body: params,
  });

  return data;
};

export const fetchSupportTasks = async (
  { pageParam }: QueryFunctionContext,
  sortBy = "",
  search = "",
  filter: (string | undefined)[] = []
) => {
  const page = pageParam ?? 1;
  const limit = 20;

  const dateFilters = ["filter.createdAt", "filter.actualDateOfExecution"];

  const filterParams = getFilterParams(filter, dateFilters);

  // Need to set boolean value, can't be an array
  if ("filter.highPriority" in filterParams) {
    const copy = filterParams["filter.highPriority"];
    filterParams["filter.highPriority"] = copy[0];
  }

  const params: {
    page: number;
    limit: number;
    search: string;
    sortBy: string;
  } = {
    page,
    limit,
    search,
    sortBy,
    ...filterParams,
  };

  const { data } = await axiosService({
    endpoint: `/task/support`,
    body: params,
  });

  return data;
};

export const fetchTasksForCalendar = async (
  type: "incoming" | "outgoing",
  filter: (string | undefined)[] = [],
  includeOverdueFilter?: boolean,
  employeeId?: number
) => {
  const dateFilters = ["filter.dateRange"];

  const filterParams = getFilterParams(filter, dateFilters);

  const params: {
    includeOverdue?: boolean;
    dateRange?: string;
    "filter.dateRange"?: string;
    employeeId?: number;
  } = {
    ...filterParams,
  };

  if (params["filter.dateRange"]) {
    params["dateRange"] = params["filter.dateRange"];
    delete params["filter.dateRange"];
  }

  if (employeeId) {
    params["employeeId"] = employeeId;
  }

  if (includeOverdueFilter) {
    params["includeOverdue"] = includeOverdueFilter;
  }

  const endpoint = employeeId
    ? `/task/${type}/calendar/subordinate`
    : `/task/${type}/calendar`;

  const { data } = await axiosService({
    endpoint,
    body: params,
  });

  return data;
};

export const fetchTasksForCards = async (type: "incoming" | "outgoing") => {
  const { data } = await axiosService({
    endpoint: `/task/${type}/card`,
  });

  return data;
};

export const fetchTaskById = async (
  id: string | undefined,
  supportTask?: boolean
) => {
  if (!id) return null;

  const { data } = await axiosService({
    endpoint: supportTask ? `/task/${id}/support` : `/task/${id}`,
  });

  return data;
};

export const fetchTaskAttachmentsById = async (taskId: number | undefined) => {
  if (!taskId) return null;

  const { data } = await axiosService({
    endpoint: `/file-attached-to-task/get-task-files/${taskId}`,
  });

  return data;
};

export const fetchTaskLinksById = async (taskId: number | undefined) => {
  if (!taskId) return null;

  const { data } = await axiosService({
    endpoint: `/documents/get-task-links/${taskId}`,
  });

  return data;
};

export const fetchPotentialExecutors = async () => {
  const { data } = await axiosService({
    endpoint: `/employees/get-potential-executurs`,
  });

  return data;
};

export const fetchEventForTaskTracking = async (
  taskId: number,
  rootTaskId: number
) => {
  const { data } = await axiosService({
    endpoint: `/event-for-task-tracking`,
    body: { taskId, rootTaskId },
  });

  return data;
};

export const fetchWorkingDays = async (
  params: Record<string, string | number>
) => {
  const { data } = await axiosService({
    endpoint: `/working-days`,
    body: params,
  });

  return data;
};

export const createTask = async (body: FormData) => {
  const { data } = await axiosService({
    endpoint: `/task`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const createRequestInSBM = async (body: FormData) => {
  const { data } = await axiosService({
    endpoint: `/task/support`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    body,
  });

  return data;
};

export const editTask = async (body: any, taskId: number) => {
  const { data } = await axiosService({
    endpoint: `/task/${taskId}`,
    method: "PUT",
    body,
  });

  return data;
};

export const editAttachedFile = async (
  body: FormData,
  taskId: number,
  fileId: number
) => {
  const { data } = await axiosService({
    endpoint: `/file-attached-to-task/update-task-file/${taskId}/${fileId}`,
    method: "PATCH",
    body,
  });

  return data;
};

export const editDraftTask = async (body: any, taskId: number) => {
  const { data } = await axiosService({
    endpoint: `/task/${taskId}/draft`,
    method: "PUT",
    body,
  });

  return data;
};

export const uploadFile = async (body: {
  requestBody: FormData;
  taskId: number;
}) => {
  const { data } = await axiosService({
    endpoint: `/task/attach/file/${body.taskId}`,
    method: "PUT",
    headers: { "Content-Type": "multipart/form-data" },
    body: body.requestBody,
  });

  return data;
};

export const uploadLinks = async (body: {
  requestBody: IAttachesTaskLinkBody;
  taskId: number;
}) => {
  const { data } = await axiosService({
    endpoint: `/task/attach/link/${body.taskId}`,
    method: "PUT",
    body: body.requestBody,
  });

  return data;
};

export const acceptExecution = async (body: IAcceptExecution) => {
  const { data } = await axiosService({
    endpoint: `/task/accept-for-execution/${body.id}`,
    method: "PUT",
    body: body.comment ? { commentOfActor: body.comment } : {},
  });

  return data;
};

export const approveExecution = async (taskId: number) => {
  if (!taskId) return null;

  const { data } = await axiosService({
    endpoint: `/task/approve-for-execution/${taskId}`,
    method: "PUT",
  });

  return data;
};

export const sendToArchive = async (taskId: number) => {
  if (!taskId) return null;

  const { data } = await axiosService({
    endpoint: `/task/${taskId}/archive`,
    method: "PUT",
  });

  return data;
};

export const viewTask = async (body: IViewTask) => {
  const { data } = await axiosService({
    endpoint: `/task/view/${body.id}`,
    method: "PUT",
    body: { taskViewed: body.view },
  });

  return data;
};

export const rejectExecution = async (body: IRejectExecution) => {
  const { data } = await axiosService({
    endpoint: `/task/reject-for-execution/${body.id}`,
    method: "PUT",
    body: { comment: body.comment },
  });

  return data;
};

export const sendForRevision = async (body: IRejectExecution) => {
  const { data } = await axiosService({
    endpoint: `/task/send-for-revision/${body.id}`,
    method: "PUT",
    body: { comment: body.comment },
  });

  return data;
};

export const abortExecution = async (body: IRejectExecution) => {
  const { data } = await axiosService({
    endpoint: `/task/abort-execution/${body.id}`,
    method: "PUT",
    body: { comment: body.comment },
  });

  return data;
};

export const completeExecution = async (body: IRejectExecution) => {
  const { data } = await axiosService({
    endpoint: `/task/end-execution/${body.id}`,
    method: "PUT",
    body: { comment: body.comment },
  });

  return data;
};

export const confirmExecution = async (body: IRejectExecution) => {
  const { data } = await axiosService({
    endpoint: `/task/confirm-execution/${body.id}`,
    method: "PUT",
    body: { comment: body.comment },
  });

  return data;
};

export const deleteTask = async (id: number) => {
  const { data } = await axiosService({
    endpoint: `task/${id}`,
    method: "DELETE",
  });

  return data;
};

export const deleteTaskAttachedFile = async (
  taskId: number,
  fileId: number
) => {
  const { data } = await axiosService({
    endpoint: `file-attached-to-task/update-task-file/${taskId}/${fileId}`,
    method: "DELETE",
  });

  return data;
};
