import React from "react";

import {
  CorrespondenceTableActions,
  getColumnNameFromArray,
  UI_CONFIG,
} from "app";
import { TFunction } from "i18next";
import { Icon } from "ui-kit";

import { Chip, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { chipStyle, COLUMN_SIZE, hexToRgba } from "@sbm/fe-utils";

export const getSignatoryName = (rowData: any, partyIndex: number) => {
  if (!rowData) return "";

  const keyPrefix = `party${partyIndex}`;

  const {
    [`${keyPrefix}PersonalDataFullName`]: longName,
    [`${keyPrefix}PersonalDataName`]: name,
    [`${keyPrefix}PersonalDataSurname`]: surname,
    [`${keyPrefix}PersonalDataPatronymic`]: patronymic,
  } = rowData;

  return (
    (longName as string) ?? `${surname || ""} ${name || ""} ${patronymic || ""}`
  );
};

export const getContractsTableColumns = (
  t: TFunction,
  theme: Theme
): GridColDef[] => [
  {
    field: "contractNumber",
    headerName: t("contract.number.date"),
    minWidth: COLUMN_SIZE * 2,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={
            <Stack>
              <Typography variant="body2">
                {params.row.contractNumber}
              </Typography>
              <Typography variant="body3">
                {params.row.dateOfSigning}
              </Typography>
            </Stack>
          }
          arrow
          disableInteractive
          placement="top"
        >
          <Stack>
            <Typography variant="body2">{params.row.contractNumber}</Typography>
            <Typography variant="body3" color={theme.palette.text.secondary}>
              {params.row.dateOfSigning}
            </Typography>
          </Stack>
        </Tooltip>
      );
    },
  },
  {
    field: "contractStatus",
    headerName: t("status"),
    sortable: false,
    filterable: false,
    minWidth: COLUMN_SIZE * 2.5,
    renderCell: (params) => {
      return (
        <Tooltip
          title={t(params.row.contractStatus)}
          arrow
          disableInteractive
          placement="top"
        >
          <div className="MuiDataGrid-cellContent">
            <Chip
              style={chipStyle(params.row.contractStatus, theme)}
              label={t(params.row.contractStatus)}
              sx={{ width: UI_CONFIG.chipWidth }}
            />
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "party1",
    headerName: getColumnNameFromArray(["party", "1"], t),
    flex: 1,
    minWidth: COLUMN_SIZE * 3,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          key={params.row.party1RoleName}
          title={
            <div>
              <Typography variant="body2">
                {params.row.party1OrganizationName}
              </Typography>
              <Typography variant="caption">
                {params.row.party1RoleName}
              </Typography>
            </div>
          }
          arrow
          disableInteractive
          placement="top"
        >
          <div key={params.row.party1RoleName}>
            <Typography variant="body2">
              {params.row.party1OrganizationName}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: hexToRgba(theme.palette.primary.main, 0.7) }}
            >
              {params.row.party1RoleName}
            </Typography>
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "party2",
    headerName: getColumnNameFromArray(["party", "2"], t),
    flex: 1,
    minWidth: COLUMN_SIZE * 3,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const signatoryName = getSignatoryName(params.row, 2);

      return (
        <Tooltip
          key={params.row.party2RoleName}
          title={
            <div>
              <Typography variant="body2">
                {params.row.party2OrganizationName || signatoryName}
              </Typography>
              <Typography variant="caption">
                {params.row.party2RoleName}
              </Typography>
            </div>
          }
          arrow
          disableInteractive
          placement="top"
        >
          <div key={params.row.party2RoleName}>
            <Typography variant="body2">
              {params.row.party2OrganizationName || signatoryName}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: hexToRgba(theme.palette.primary.main, 0.7) }}
            >
              {params.row.party2RoleName}
            </Typography>
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "expirationDate",
    headerName: t("expiration.date"),
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["expirationDate"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">
            {params.row["expirationDate"]}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "contractCategoryName",
    headerName: t("category"),
    sortable: false,
    minWidth: COLUMN_SIZE * 2,
    filterable: false,
    renderCell: (params) => {
      const category = params.row.contractCategoryName;

      return (
        <Tooltip
          title={
            <Stack flexDirection="row" alignItems="center">
              <Typography variant="body2">
                {params.row.contractCategoryName}
              </Typography>
              <Icon
                name={
                  category.includes("Доходный")
                    ? "MoveDownLeftIcon"
                    : "MoveUpRightIcon"
                }
              />
            </Stack>
          }
          arrow
          disableInteractive
          placement="top"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography variant="body2">
              {params.row.contractCategoryName}
            </Typography>
            <Icon
              name={
                category.includes("Доходный")
                  ? "MoveDownLeftIcon"
                  : "MoveUpRightIcon"
              }
              color={
                category.includes("Доходный")
                  ? theme.palette.success.main
                  : theme.palette.error.main
              }
            />
          </Stack>
        </Tooltip>
      );
    },
  },
  {
    field: "contractTypeName",
    headerName: t("type"),
    flex: 1,
    minWidth: COLUMN_SIZE * 3.5,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          key={params.row.contractSubtypeName}
          title={
            <div>
              <Typography variant="body2">
                {params.row.contractTypeName}
              </Typography>
              <Typography variant="caption">
                {params.row.contractSubtypeName}
              </Typography>
            </div>
          }
          arrow
          disableInteractive
          placement="top"
        >
          <div key={params.row.contractSubtypeName}>
            <Typography variant="body2">
              {params.row.contractTypeName}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: hexToRgba(theme.palette.primary.main, 0.7) }}
            >
              {params.row.contractSubtypeName}
            </Typography>
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "constructionComplexName",
    headerName: t("construction.complex"),
    minWidth: COLUMN_SIZE * 3,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["constructionComplexName"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">
            {params.row["constructionComplexName"]}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "contractTotalPrice",
    headerName: t("price.total"),
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["contractTotalPrice"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">
            {params.row["contractTotalPrice"]}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "createdBy",
    headerName: t("contract.created.by"),
    minWidth: COLUMN_SIZE * 2,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["createdBy"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">{params.row["createdBy"]}</Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "createdAt",
    headerName: t("date.of.creation"),
    minWidth: COLUMN_SIZE * 2,
    sortable: true,
    filterable: false,
    renderCell: (params) => {
      const date = params.row.createdAt?.split(",");
      const hasThreeItems = date?.length === 3;

      if (hasThreeItems) {
        return (
          <Tooltip
            title={
              <div>
                <div>{`${date[0] || ""} ${date[1] || ""}`}</div>
                <div>{`${date[2] || ""}`}</div>
              </div>
            }
            arrow
            disableInteractive
            placement="top"
          >
            <div>
              <div>{`${date[0] || ""} ${date[1] || ""}`}</div>
              <div>{`${date[2] || ""}`}</div>
            </div>
          </Tooltip>
        );
      }
      return (
        <Tooltip
          title={
            <div>
              <div>{`${date?.[0] || ""}`}</div>
              <div>{`${date?.[1] || ""}`}</div>
            </div>
          }
          arrow
          disableInteractive
          placement="top"
        >
          <div>
            <div>{`${date?.[0] || ""}`}</div>
            <div>{`${date?.[1] || ""}`}</div>
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "responsibleForPreparationContractName",
    headerName: t("responsible.of.contract.preparation"),
    minWidth: COLUMN_SIZE * 2,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["responsibleForPreparationContractName"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">
            {params.row["responsibleForPreparationContractName"]}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "id",
    headerName: t("internal.number"),
    sortable: true,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["id"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">{params.row["id"]}</Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "supplementaryAgreementsCount",
    headerName: t("sa.signed"),
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Tooltip
          title={params.row["supplementaryAgreementsCount"]}
          arrow
          disableInteractive
          placement="top"
        >
          <Typography variant="body2">
            {params.row["supplementaryAgreementsCount"]}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "attachedFiles",
    headerName: "",
    sortable: false,
    filterable: false,
    minWidth: COLUMN_SIZE * 1.5,
    renderCell: (params) => {
      return (
        <Stack justifyContent="center" flexDirection="row" width="100%">
          {params.row.hasAttachedFile ? (
            <Icon
              name="Paperclip"
              size={16}
              strokeWidth={2}
              color={theme.palette.primary.main}
            />
          ) : null}
        </Stack>
      );
    },
  },
  {
    field: "actions",
    headerName: "",
    width: COLUMN_SIZE,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Stack
          display="flex"
          justifyContent={
            params.row.addedToMyDocuments ? "space-between" : "end"
          }
          alignItems="center"
          flexDirection="row"
          width="100%"
        >
          {params.row.addedToMyDocuments ? (
            <Icon
              name="Files"
              size={18}
              strokeWidth={2}
              color={theme.palette.primary.main}
            />
          ) : null}
          <CorrespondenceTableActions
            documentId={params.row.documentId}
            hadAddedDocument={params.row.addedToMyDocuments}
          />
        </Stack>
      );
    },
  },
];

export const CONTRACTS_FILTERABLE_FIELDS = [
  {
    title: "category",
    field: "contractCategoryId",
    options: [],
    optionType: "radio",
    value: "",
  },
  {
    title: "type",
    field: "contractTypeId",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "subtype",
    field: "contractSubtypeId",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "status",
    field: "contractStatus",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: ["party", "1"],
    field: "party1OrganizationId",
    options: [],
    value: "",
    multiSelect: true,
  },

  {
    title: ["party", "2"],
    field: "party2OrganizationId",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "construction.complex",
    field: "constructionComplexId",
    options: [],
    value: "",
    multiSelect: true,
  },
  {
    title: "date.of.signing",
    field: "dateOfSigning",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "expiration.date",
    field: "expirationDate",
    options: [],
    value: "",
    optionType: "range",
  },
  {
    title: "responsible.of.contract.preparation",
    field: "responsibleForPreparationContractId",
    options: [],
    value: "",
    multiSelect: true,
  },
];
