import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useGetTaskById } from "api";
import { closeDrawer, useAppDispatch, useQueryParams } from "app";
import { DotsLoader, Drawer } from "ui-kit";

import { Stack } from "@mui/material";
import { capitalize, getAPIErrorMessage } from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { TypeOfTheTaskEnum } from "@types";

import { TaskCard } from "../TaskCard";
import { TaskDetails } from "../TaskDetails";
import { FeedbackRequestTaskDetail } from "../TaskDetails/FeedbackRequestTaskDetail";

export const TaskPreview: React.FC<{ isSubordinateTask?: boolean }> = ({
  isSubordinateTask,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("tasks");
  const { get, remove, clear, set } = useQueryParams();

  const id = get("selectedTaskId") as string;

  const { data, isLoading, error } = useGetTaskById(id, {
    enabled: Boolean(id),
  });

  if (error) {
    const errorMSG = getAPIErrorMessage(error);
    if (errorMSG) {
      toast.error(errorMSG);
    }
  }

  if (isLoading) return <DotsLoader />;
  if (!data) return null;

  const handleClose = () => {
    dispatch(closeDrawer());
    remove("selectedTaskId");
  };

  const handleClick = () => {
    handleClose();
    clear();
    set("selectedTaskId", id);
    navigate(`/tasks/${id}`);
  };

  return (
    <Drawer
      open
      resizable={false}
      anchor="right"
      onClose={handleClose}
      title={t(`types.${data.typeOfTheTask}`)}
      actions={
        <Stack
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
          gap={4}
        >
          <Button
            size="large"
            color="secondary"
            variant="outlined"
            onClick={handleClose}
            sx={{ width: "fit-content" }}
          >
            {capitalize(t("cancel"))}
          </Button>
          {!isSubordinateTask && (
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleClick}
              sx={{ width: "fit-content" }}
            >
              {capitalize(t("go.to.task"))}
            </Button>
          )}
        </Stack>
      }
    >
      <Stack gap={2}>
        <TaskCard isInDrawer data={data} />
        {data.typeOfTheTask === TypeOfTheTaskEnum.requestToSupport ? (
          <FeedbackRequestTaskDetail isInDrawer data={data} />
        ) : (
          <TaskDetails isInDrawer data={data} />
        )}
      </Stack>
    </Drawer>
  );
};
