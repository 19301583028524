import styled from "styled-components";
import { FLEX_BETWEEN, FLEX_ROW_FULL } from "ui-kit";

export const PresenceIndicationWrapper = styled.div``;

export const IndicationWrapper = styled.div<{ $bgColor: string }>`
  width: 4px;
  height: 16px;
  border-radius: 1px;
  background-color: ${({ $bgColor }) => $bgColor};
`;

export const TooltipIndicationWrapper = styled.div<{ $color: string }>`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 2px;
  ${FLEX_ROW_FULL};
  padding: ${({ theme }) => theme.spacing()};
  height: 14px;
  min-width: 14px;
  color: ${({ $color }) => $color};
`;

export const GroupItem = styled.div`
  ${FLEX_BETWEEN};
  gap: ${({ theme }) => theme.spacing()};
  margin: 2px 0;
  gap: ${({ theme }) => theme.spacing(4)};
`;
