import styled from "styled-components";
import {
  FLEX_BETWEEN,
  FLEX_COLUMN_FULL,
  FLEX_ROW,
  FLEX_ROW_ALIGN_CENTER,
} from "ui-kit";

import { Card } from "@mui/material";

import project_bg from "../../../../assets/backgrounds/project-main-card.svg";

export const WidgetWrapper = styled(Card)`
  display: flex;
  min-height: 272px;
  border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(4)};
}`;
export const LeftSection = styled.div<{ $hasShowBg: boolean }>`
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};
  width: ${({ $hasShowBg }) => ($hasShowBg ? "100%" : "50%")};

}`;
export const RightSection = styled.div`
  width: 50%;
  background-image: url(${project_bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const IconWrapper = styled.div`
  ${FLEX_COLUMN_FULL};
  padding: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.spacing(0, 0, 3, 3)};
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const WidgetHeaderWrapper = styled.div`
  ${FLEX_BETWEEN};
  min-height: 72px;
  .widget_icon_title {
    ${FLEX_ROW_ALIGN_CENTER};
    gap: ${({ theme }) => theme.spacing(4)};
  }
`;

export const WidgetDescriptionWrapper = styled.div`
  ${FLEX_ROW};
  padding: ${({ theme }) => theme.spacing(6, 0)};
`;

export const WidgetActionWrapper = styled.div`
  ${FLEX_ROW};
  justify-content: end;
  .manage_button {
    gap: ${({ theme }) => theme.spacing(2)};
    ${FLEX_ROW_ALIGN_CENTER};
  }
`;

export const WidgetChartWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  width: 100%;
  height: 100px;
`;
