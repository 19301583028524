import React, { useCallback } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  useConfirmApprovalRoute,
  useGetApprovalRoutesSelectForContract,
  useGetConfirmedApprovalRoutes,
  useLaunchApprovalRoute,
} from "api";
import {
  closeDrawer,
  getContractById,
  getSupplementaryAgreement,
  IContractForm,
  openDrawer,
  setModal,
  UI_CONFIG,
  useAppDispatch,
  useAppSelector,
} from "app";
import { AxiosError } from "axios";
import { Icon, Table } from "ui-kit";

import { Chip, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro";
import {
  COLUMN_SIZE,
  getAPIErrorMessage,
  getLocaleDateFormat,
  getPersonalDataName,
  SnackbarMessages,
} from "@sbm/fe-utils";
import { Button } from "@sbm/ui-components";
import { useQueryClient } from "@tanstack/react-query";
import {
  ApproverActions,
  ContractStatusEnum,
  DrawerVariantsEnum,
  EnumApproverDocumentStatusType,
  IApprovalRoute,
  IConfirmRoute,
  IDocumentById,
  ModalVariants,
  TSupAgreement,
  TypeOfObjectInternalApprovalEnum,
} from "@types";

import { AddApproverContainer } from "../AddApproverContainer";
import { getAgreementQueueTableColumns } from "../helpers";
import { ApproverSection, ChangeRoute, ListOfApprovals } from "./components";
import { getAgreementDataForConfirm, getAgreementStatus } from "./helpers";
import { ButtonWrapper, TitleWrapper, Wrapper } from "./styles";

interface AgreementContainerProps {
  data: TSupAgreement | IContractForm | IDocumentById;
  type: TypeOfObjectInternalApprovalEnum;
}

export const AgreementContainer: React.FC<AgreementContainerProps> = ({
  data,
  type,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { userProfile } = useAppSelector((state) => state.auth);

  const isCorrespondenceApproval =
    type === TypeOfObjectInternalApprovalEnum.outgoingDocument ||
    type === TypeOfObjectInternalApprovalEnum.serviceNote;

  const id = data.id;
  const responsibleEmployeeId = data.responsibleEmployeeId;
  const responsibleExternalSubstitutionId =
    data.responsibleExternalSubstitutionId;

  const status = getAgreementStatus(data, type);
  const personalDataEmployeeIds = userProfile?.personalData?.employees?.map(
    (emp) => emp.id
  );
  const externalSubstitutionsIds =
    userProfile?.personalData?.employees?.[0]?.externalSubstitutions?.map(
      (i) => i.id
    );

  const isResponsibleEmployeeCurrentUser = Boolean(
    (responsibleEmployeeId &&
      personalDataEmployeeIds?.includes(responsibleEmployeeId)) ||
      (responsibleExternalSubstitutionId &&
        externalSubstitutionsIds?.includes(responsibleExternalSubstitutionId))
  );

  const isDraft = status === ContractStatusEnum.draft;
  const isUnderSigning = status === ContractStatusEnum.underSigning;
  const isPreparation = status === ContractStatusEnum.preparation;

  const { selectedApprovalRoute } = useAppSelector((state) => state.contracts);
  const { drawer } = useAppSelector((state) => state.global);

  const [currentRoute, setCurrentRoute] = React.useState<
    IApprovalRoute | null | undefined
  >(null);

  const isApprovalEnded = Boolean(
    currentRoute?.approvers?.every((item) => {
      return item.status?.statusSingleApproval === ApproverActions.approved;
    })
  );

  const isLastQueue =
    currentRoute &&
    currentRoute.currentQueueNumber ===
      currentRoute.approvers?.at(-1)?.queueNumber;

  const approvalEndDate =
    isApprovalEnded &&
    currentRoute?.approvers &&
    currentRoute.approvers.at(-1)?.factDateIterationEnding;

  const isStartApprovalButtonAvailable = React.useMemo(() => {
    if (!type) return false;

    // Contract and Supplementary agreement
    if (
      type === TypeOfObjectInternalApprovalEnum.contract ||
      type === TypeOfObjectInternalApprovalEnum.supplementaryAgreement
    ) {
      // For Contracts this button available only in preparation status
      return isPreparation;
    }

    // Correspondence
    if (
      type === TypeOfObjectInternalApprovalEnum.outgoingDocument ||
      type === TypeOfObjectInternalApprovalEnum.serviceNote
    ) {
      // For Correspondence this button available only in preparation status
      return isDraft && isResponsibleEmployeeCurrentUser;
    }

    return false;
  }, [isDraft, isPreparation, isResponsibleEmployeeCurrentUser, type]);

  const { data: approvalRoutesMainTableData, isLoading } =
    useGetApprovalRoutesSelectForContract({
      id,
      type,
    });

  const { data: confirmedRoutes } = useGetConfirmedApprovalRoutes({
    id,
    type,
  });

  const renderApprovalDates = useCallback(
    (confirmedRoutes: IApprovalRoute) => {
      const {
        statusApprovalProcessInternalObject,
        dateApprovalBeginning,
        planDateApprovalApprovalEnding,
        planDateIterationEnding,
        factDateApprovalApprovalEnding,
      } = confirmedRoutes;

      switch (statusApprovalProcessInternalObject) {
        case EnumApproverDocumentStatusType.under_consideration:
        case EnumApproverDocumentStatusType.fixing_problems:
          return (
            <Stack direction="column">
              {dateApprovalBeginning && (
                <Typography variant="body2" color="text.secondary">
                  {`${t("approval.start.date.and.time")} ${getLocaleDateFormat(
                    dateApprovalBeginning,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
              {planDateApprovalApprovalEnding && (
                <Typography variant="body2" color="text.secondary">
                  {`${t(
                    "planned.approval.end.date.and.time"
                  )} ${getLocaleDateFormat(
                    planDateApprovalApprovalEnding,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
              {planDateIterationEnding && (
                <Typography variant="body2" color="text.secondary">
                  {`${t(
                    "expected.approval.end.date.and.time"
                  )} ${getLocaleDateFormat(planDateIterationEnding, "hh:mm")}`}
                </Typography>
              )}
            </Stack>
          );
        case EnumApproverDocumentStatusType.agreement_completed:
          return (
            <Stack direction="column">
              {dateApprovalBeginning && (
                <Typography variant="body2" color="text.secondary">
                  {`${t("approval.start.date.and.time")} ${getLocaleDateFormat(
                    dateApprovalBeginning,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
              {planDateApprovalApprovalEnding && (
                <Typography variant="body2" color="text.secondary">
                  {`${t(
                    "planned.approval.end.date.and.time"
                  )} ${getLocaleDateFormat(
                    planDateApprovalApprovalEnding,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
              {factDateApprovalApprovalEnding && (
                <Typography variant="body2" color="text.secondary">
                  {`${t(
                    "actual.approval.end.date.and.time"
                  )} ${getLocaleDateFormat(
                    factDateApprovalApprovalEnding,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
            </Stack>
          );
        case EnumApproverDocumentStatusType.agreement_interrupted:
          return (
            <Stack direction="column">
              {dateApprovalBeginning && (
                <Typography variant="body2" color="text.secondary">
                  {`${t("approval.start.date.and.time")} ${getLocaleDateFormat(
                    dateApprovalBeginning,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
              {planDateApprovalApprovalEnding && (
                <Typography variant="body2" color="text.secondary">
                  {`${t(
                    "planned.approval.end.date.and.time"
                  )} ${getLocaleDateFormat(
                    planDateApprovalApprovalEnding,
                    "hh:mm"
                  )}`}
                </Typography>
              )}
            </Stack>
          );
        default:
          return <></>;
      }
    },
    [t]
  );

  const onSubmitSuccess = async () => {
    await queryClient.invalidateQueries(["get_confirmed_approval_routes"]);
    await queryClient.invalidateQueries(["get_select_for"]);
    toast.success(SnackbarMessages.success);
  };

  const onLaunchSubmitSuccess = async () => {
    if (type === TypeOfObjectInternalApprovalEnum.supplementaryAgreement) {
      await dispatch(getSupplementaryAgreement(String(id)));
    } else if (type === TypeOfObjectInternalApprovalEnum.contract) {
      await dispatch(getContractById(String(id)));
    } else if (isCorrespondenceApproval) {
      await queryClient.invalidateQueries(["get_document_by_id"]);
    }
    await queryClient.invalidateQueries(["get_confirmed_approval_routes"]);
    toast.success(SnackbarMessages.success);
  };

  const onSubmitError = (error: unknown) => {
    const errorMessage = getAPIErrorMessage(error as AxiosError);
    toast.error(errorMessage);
  };

  const { mutate: confirmRoute, isLoading: isConfirmApprovalLoading } =
    useConfirmApprovalRoute(onSubmitSuccess, onSubmitError);

  const { mutate: startApprovalProcess, isLoading: isStartApprovalLoading } =
    useLaunchApprovalRoute(onLaunchSubmitSuccess, onSubmitError);

  const handleChangeRoute = () => {
    dispatch(openDrawer(DrawerVariantsEnum.changeTypicalRoute));
  };

  const handleOpenAddApprover = () => {
    dispatch(openDrawer(DrawerVariantsEnum.addApprover));
  };

  const handleConfirm = () => {
    if (!currentRoute) return;

    // Start Approval
    if (confirmedRoutes) {
      const dataForLaunch = {
        objectInternalApprovalProcessId: currentRoute.id,
      };

      startApprovalProcess(dataForLaunch);
      return;
    }

    // Confirm Route for Service Note and Outgoing documents
    if (
      type === TypeOfObjectInternalApprovalEnum.outgoingDocument ||
      type === TypeOfObjectInternalApprovalEnum.serviceNote
    ) {
      const dataForConfirm = {
        approvers:
          approvalRoutesMainTableData?.[0]?.approvers?.map((i) => {
            const result: {
              externalSubstitutionId?: number;
              employeeId?: number;
              queueNumber?: number;
              amountOfDays?: number;
            } = {
              queueNumber: i.queueNumber,
              amountOfDays: i.amountOfDays,
            };

            if (i.employeeId) {
              result["employeeId"] = i.employeeId;
            } else if (i.externalSubstitutionId) {
              result["externalSubstitutionId"] = i.externalSubstitutionId;
            }

            return result;
          }) || [],
        ...getAgreementDataForConfirm(data, type),
      };

      confirmRoute(dataForConfirm as IConfirmRoute);
      return;
    }

    // Confirm Route
    const dataForConfirm = {
      typicalApprovalRouteForContractId: currentRoute.id,
      ...getAgreementDataForConfirm(data, type),
    } as IConfirmRoute;

    confirmRoute(dataForConfirm);
  };

  const handleOpenApprovalRouteConfirmationModal = () => {
    if (confirmedRoutes) return handleConfirm();

    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.confirmApprovalRoute,
        content: {
          confirmNavigation: handleConfirm,
        },
      })
    );
  };

  const handleOpenApprovalList = () => {
    dispatch(openDrawer(DrawerVariantsEnum.listOfApprovals));
  };

  const handleCloseDrawer = () => dispatch(closeDrawer());

  const selectedDefaultApprovalRoute =
    confirmedRoutes ||
    approvalRoutesMainTableData?.find((item) => item.routeByDefault);

  const rows: GridValidRowModel[] = React.useMemo(() => {
    const getApprovalRouteToShow = () => {
      if (confirmedRoutes) return confirmedRoutes;

      if (selectedApprovalRoute) {
        return approvalRoutesMainTableData?.find(
          (i) => i.id === selectedApprovalRoute.id
        );
      }

      return selectedDefaultApprovalRoute;
    };

    const currentRoute = getApprovalRouteToShow();
    const approvalRouteToShow = currentRoute?.approvers;

    setCurrentRoute(currentRoute);

    return data && approvalRouteToShow
      ? approvalRouteToShow.map((item, index) => ({
          ...item,
          id: index,
          approverType:
            item.structuralUnit?.nameOfStructuralUnit ||
            t(item.approverMission),
          queueNumber: item.queueNumber,
          structuralUnitId: item.structuralUnitId || undefined,
          position: t(item.positionName),
          longName: item.personalData
            ? getPersonalDataName(item.personalData)
            : "",
          organizationName: item.organizationName,
          duration: item.termSingleApproval
            ? `${item.termSingleApproval} ${t("working.days")}`
            : "",
          statusForAction: item?.status?.statusSingleApproval,
          status: t(item?.status?.statusSingleApproval as string),
          comment: item?.status?.comment || "",
          singleApprovalId: item?.status?.singleApprovalId,
        }))
      : [];
  }, [
    selectedApprovalRoute,
    approvalRoutesMainTableData,
    confirmedRoutes,
    selectedDefaultApprovalRoute,
    data,
    t,
  ]);

  const columns: GridColDef[] = React.useMemo(() => {
    const defaultColumns: GridColDef[] = [
      {
        field: "position",
        minWidth: COLUMN_SIZE * 5,
        headerName: t("approval.subject"),
        sortable: false,
        filterable: false,
        cellClassName: "approval-table__position-cell",
        renderCell: (params) => {
          return (
            <ApproverSection
              rowData={params.row}
              isAgreed={isApprovalEnded}
              isDraft={isDraft}
              responsibleEmployeeId={responsibleEmployeeId}
              isResponsibleEmployeeCurrentUser={
                isResponsibleEmployeeCurrentUser
              }
            />
          );
        },
      },
    ];

    if (!isApprovalEnded) {
      const queueColumns = getAgreementQueueTableColumns(
        rows,
        t,
        theme,
        isDraft,
        currentRoute?.currentQueueNumber
      );

      return [...defaultColumns, ...queueColumns];
    }

    const agreedColumns: GridColDef[] = [
      {
        field: "status",
        minWidth: COLUMN_SIZE * 3,
        headerName: t("status"),
        sortable: false,
        filterable: false,
        cellClassName: "agreed-column__status-cell",
        renderCell: (params) => {
          return (
            <div className="MuiDataGrid-cellContent">
              <Chip
                label={params.row.status}
                color="success"
                sx={{
                  minWidth: UI_CONFIG.chipWidth,
                  fontWeight: 600,
                  color: theme.palette.primary.contrastText,
                }}
              />
            </div>
          );
        },
      },
      {
        field: "comment",
        minWidth: COLUMN_SIZE * 3,
        flex: 1,
        headerName: t("comment"),
        sortable: false,
        filterable: false,
      },
    ];

    return [...defaultColumns, ...agreedColumns];
  }, [
    isResponsibleEmployeeCurrentUser,
    t,
    isApprovalEnded,
    rows,
    theme,
    isDraft,
    currentRoute?.currentQueueNumber,
    responsibleEmployeeId,
  ]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="h6" color="text.secondary">
              {t("approval.route")}
            </Typography>

            {confirmedRoutes?.id && !isDraft && (
              <Tooltip
                arrow
                placement="top"
                title={t("list_of_approvals_tooltip")}
              >
                <Stack alignItems="center">
                  <Icon
                    name="FileClock"
                    color={theme.palette.secondary.main}
                    onClick={handleOpenApprovalList}
                  />
                </Stack>
              </Tooltip>
            )}
          </Stack>

          {/* {isApprovalEnded && approvalEndDate ? (
            <Typography variant="body2" color="text.secondary">
              {`${t("approval.completion.date")} ${getLocaleDateFormat(
                approvalEndDate
              )}`}
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {currentRoute?.planDateIterationEnding &&
                !isDraft &&
                `${t("planned.approval.completion.date")} ${getLocaleDateFormat(
                  currentRoute.planDateIterationEnding
                )}`}
            </Typography>
          )} */}
          {confirmedRoutes && renderApprovalDates(confirmedRoutes)}
        </Stack>

        <ButtonWrapper>
          {confirmedRoutes ? (
            !isUnderSigning &&
            !isApprovalEnded &&
            !isLastQueue && (
              <Stack direction="row" alignItems="start" gap={4}>
                {isStartApprovalButtonAvailable && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    loading={isStartApprovalLoading}
                    disabled={Boolean(
                      !rows.length || !isResponsibleEmployeeCurrentUser
                    )}
                    onClick={handleOpenApprovalRouteConfirmationModal}
                  >
                    {t("start.approval.process")}
                  </Button>
                )}

                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenAddApprover}
                >
                  {t("add.approver")}
                </Button>
              </Stack>
            )
          ) : (
            <Stack direction="row" alignItems="start" gap={4}>
              <Button
                size="medium"
                variant="outlined"
                color="secondary"
                onClick={handleChangeRoute}
                disabled={Boolean(
                  isDraft ||
                    (responsibleEmployeeId && !isResponsibleEmployeeCurrentUser)
                )}
              >
                {t("other.route")}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                loading={isConfirmApprovalLoading}
                disabled={Boolean(
                  !rows.length || !isResponsibleEmployeeCurrentUser || isDraft
                )}
                onClick={handleOpenApprovalRouteConfirmationModal}
              >
                {t("confirm.route")}
              </Button>
            </Stack>
          )}
        </ButtonWrapper>
      </TitleWrapper>

      <Table
        rows={rows}
        columns={columns}
        hasPagination={false}
        checkboxSelection={false}
        loading={isLoading}
        getRowHeight={() => "auto"}
      />

      {drawer === DrawerVariantsEnum.addApprover && id ? (
        <AddApproverContainer
          id={id}
          onClose={handleCloseDrawer}
          currentQueueNumber={currentRoute?.currentQueueNumber}
          isDraft={isDraft}
          type={type}
        />
      ) : null}

      {drawer === DrawerVariantsEnum.changeTypicalRoute ? (
        <ChangeRoute
          data={approvalRoutesMainTableData}
          onClose={handleCloseDrawer}
          currentRoute={currentRoute}
        />
      ) : null}

      {confirmedRoutes?.id &&
        !isDraft &&
        drawer === DrawerVariantsEnum.listOfApprovals && (
          <ListOfApprovals
            onClose={handleCloseDrawer}
            objectInternalApprovalProcessId={confirmedRoutes.id}
          />
        )}
    </Wrapper>
  );
};
