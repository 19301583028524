// @ts-nocheck
import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getExternalOrganizationStaff } from "api";
import { axiosService } from "app";

import { Stack } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import { FormAutocomplete, FormRadio, FormSelect } from "@sbm/ui-components";
import {
  IEmployee,
  IOrganization,
  IPersonalData,
  LegalEntityEnum,
  StructuralUnitTypesEnum,
} from "@types";

import { DetailsStepProps } from "../DetailsStep";

export const OutgoingDocumentActorForm: React.FC<
  {
    position: number;
  } & Pick<DetailsStepProps, "control" | "setValue" | "isEditMode">
> = ({ setValue, control, position, isEditMode }) => {
  const { t } = useTranslation("correspondence");

  const { recipients } = useWatch({ control });

  const legalEntity = recipients?.[position]?.legalEntity;
  const recipientOrganizationId =
    recipients?.[position]?.recipientOrganizationId;
  const recipientPersonalDataId =
    recipients?.[position]?.recipientPersonalDataId;
  const recipientEmployeeId = recipients?.[position]?.recipientEmployeeId;
  const externalOrganizationStaffId =
    recipients?.[position]?.externalOrganizationStaffId;

  const isLegalEntity =
    typeof legalEntity === "string" ? legalEntity === "true" : legalEntity;

  const [inputOrganizationValue, setInputOrganizationValue] =
    React.useState("");
  const [inputPersonalDataValue, setInputPersonalDataValue] =
    React.useState("");
  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [organizationOptions, setOrganizationOptions] = React.useState<
    { title: string; id?: number; belongingToTheEvGroup: boolean }[]
  >([]);
  const [personalDataOptions, setPersonalDataOptions] = React.useState<
    { title: string; id?: number }[]
  >([]);
  const [employeeOptions, setEmployeeOptions] = React.useState<
    { title: string; id?: number; employeePosition: "string" }[]
  >([]);

  const selectedOrganization = organizationOptions.find(
    (i) => i.id === recipientOrganizationId
  );

  const isEmployeeSelection = Boolean(
    selectedOrganization?.belongingToTheEvGroup
  );

  const legalEntityOptions = useMemo(
    () =>
      Object.values(LegalEntityEnum).map((option) => ({
        option: t(option),
        value: option === LegalEntityEnum.legalEntity,
      })),
    [t]
  );

  const senderPositionOptions = useMemo(() => {
    if (isLegalEntity && employeeOptions?.length > 0) {
      return employeeOptions.map((option) => {
        return {
          option: option.employeePosition,
          value: option.employeePosition,
        };
      });
    }

    return [];
  }, [employeeOptions, isLegalEntity]);

  // Change listeners
  const handleOrganizationInputChange = (val: string, reason: string) => {
    setInputOrganizationValue(val);

    if (reason === "input") return "";
    // Delete action
    if (reason === "clear") {
      setValue(`recipients[${position}].recipientPersonalDataId`, undefined);
      setValue(`recipients[${position}].recipientOrganizationId`, undefined);
      setValue(`recipients[${position}].recipientEmployeeId`, undefined);
      setValue(
        `recipients[${position}].externalOrganizationStaffId`,
        undefined
      );
      return;
    }
    // Action is select from the list, should be stored under <`recipients[${position}].recipientPersonalDataId`>
    if (reason === "reset") {
      const option = organizationOptions.find((i) => i.title === val);
      if (!option) return;

      setValue(`recipients[${position}].recipientOrganizationId`, option.id);
    }
  };

  const handlePersonalDataInputChange = (val: string, reason: string) => {
    setInputPersonalDataValue(val);
    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue(`recipients[${position}].recipientPersonalDataId`, undefined);
      return;
    }
    // Action is select from the list, should be stored under <`recipients[${position}].recipientPersonalDataId`>
    if (reason === "reset") {
      const option = personalDataOptions.find((i) => i.title === val);
      if (!option) return;
      setValue(`recipients[${position}].recipientPersonalDataId`, option.id);
      setValue(`recipients[${position}].recipientEmployeeId`, undefined);
      setValue(
        `recipients[${position}].externalOrganizationStaffId`,
        undefined
      );
    }
  };

  const handleEmployeeInputChange = (val: string, reason: string) => {
    setInputEmployeeValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue(`recipients[${position}].recipientEmployeeId`, undefined);
      setValue(`recipients[${position}].recipientEmployeePosition`, undefined);
      setValue(
        `recipients[${position}].externalOrganizationStaffId`,
        undefined
      );
      return;
    }
    // Action is select from the list, should be stored under <`recipients[${position}].recipientPersonalDataId`>
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;
      setValue(`recipients[${position}].recipientPersonalDataId`, undefined);
      setValue(
        `recipients[${position}].recipientEmployeePosition`,
        option.employeePosition
      );

      const fieldToChange = isEmployeeSelection
        ? `recipients[${position}].recipientEmployeeId`
        : `recipients[${position}].externalOrganizationStaffId`;

      setValue(fieldToChange, option.id);

      if (fieldToChange === `recipients[${position}].recipientEmployeeId`) {
        setValue(
          `recipients[${position}].externalOrganizationStaffId`,
          undefined
        );
      } else {
        setValue(`recipients[${position}].recipientEmployeeId`, undefined);
      }
    }
  };

  const handleChangeListener = () => {
    setInputPersonalDataValue("");
    setInputOrganizationValue("");
    setInputEmployeeValue("");
    setValue(`recipients[${position}].recipientPersonalDataId`, undefined, {
      shouldDirty: true,
    });
    setValue(`recipients[${position}].recipientEmployeeId`, undefined, {
      shouldDirty: true,
    });
    setValue(`recipients[${position}].recipientOrganizationId`, undefined, {
      shouldDirty: true,
    });
    setValue(`recipients[${position}].recipientEmployeePosition`, undefined, {
      shouldDirty: true,
    });
    setValue(`recipients[${position}].externalOrganizationStaffId`, undefined, {
      shouldDirty: true,
    });

    setValue("emailId", undefined, { shouldDirty: true });
  };

  // Default values
  const getOrganizationDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !organizationOptions.length) return "";

      const organization = organizationOptions.find((i) => i.id === id);

      if (!organization) return "";
      return organization.title;
    },
    [organizationOptions]
  );

  const getPersonalDataDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !personalDataOptions.length) return "";

      const option = personalDataOptions.find((i) => i.id === id);
      if (!option) return "";
      return option.title;
    },
    [personalDataOptions]
  );

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return "";

      const option = employeeOptions.find((i) => i.id === id);
      if (!option) return "";
      return option.title;
    },
    [employeeOptions]
  );

  // Fetch requests
  const fetchOrganizations = React.useCallback(
    async (search: string) => {
      const { data } = await axiosService({
        endpoint: `organizations`,
        body: { search },
      });

      const options = data
        ? (data.items as IOrganization[])
            .map((i) => ({
              title: i.briefTextOrganizationName || i.shortName,
              id: i.id,
              belongingToTheEvGroup: i.belongingToTheEvGroup,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (recipientOrganizationId && isEditMode) {
        const exists = options.findIndex(
          (i) => i.id === recipientOrganizationId
        );

        if (exists === -1) {
          const { data } = await axiosService({
            endpoint: `organizations/${recipientOrganizationId}`,
          });

          const optionToAdd = {
            title: data.briefTextOrganizationName,
            id: data.id,
            belongingToTheEvGroup: data.inHolding,
          };

          options.push(optionToAdd);
        }
      }
      return options || [];
    },
    [isEditMode, recipientOrganizationId]
  );

  const fetchIndividuals = React.useCallback(async (search: string) => {
    const { data } = await axiosService({
      endpoint: `personal-data`,
      body: { search },
    });

    const options = data
      ? (data.items as IPersonalData[])
          .map((i) => ({
            title: getPersonalDataName(i),
            id: i.id,
          }))
          .filter((i) => Boolean(i.title))
      : [];

    return options;
  }, []);

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      if (!recipientOrganizationId) return [];

      // Case Selected organization is not in holding
      if (!isEmployeeSelection) {
        const data = await getExternalOrganizationStaff(
          search,
          recipientOrganizationId
        );

        return data
          ? data.items
              .map((i) => ({
                title: getPersonalDataName(i.personalData, true),
                id: i.id,
                employeePosition: i.positionExternalOrganization || "",
              }))
              .filter((i) => Boolean(i.title))
          : [];
      }

      // Case Selected Organization in holding
      const { data } = await axiosService({
        endpoint: `employees`,
        body: {
          search,
          "filter.organizationId": recipientOrganizationId,
          "filter.structuralUnitType": [
            StructuralUnitTypesEnum.mainGoverningBody,
            StructuralUnitTypesEnum.goveringBody,
          ],
        },
      });

      const result = data
        ? (data.items as IEmployee[])
            .map((i) => ({
              title: getPersonalDataName(i.personalData, true),
              id: i.id,
              employeePosition: i.staffUnit?.position?.namePosition || "",
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (isEditMode && recipientEmployeeId) {
        const isInDefaultGetList = !result.find(
          (r) => r.id !== recipientEmployeeId
        );

        if (!isInDefaultGetList) {
          const { data: employee } = await axiosService({
            endpoint: `employees/${recipientEmployeeId}`,
          });

          if (employee) {
            result.push({
              title: getPersonalDataName(employee.personalData, true),
              id: employee.id,
              employeePosition: employee.staffUnit.namePosition,
            });
          }
        }
      }

      return result;
    },
    [
      isEmployeeSelection,
      isEditMode,
      recipientEmployeeId,
      recipientOrganizationId,
    ]
  );

  return (
    <Stack gap={4}>
      <FormRadio
        name={`recipients[${position}].legalEntity`}
        control={control}
        values={legalEntityOptions}
        onChangeListener={handleChangeListener}
      />

      {isLegalEntity && (
        <FormAutocomplete
          required
          freeSolo={false}
          inputValue={inputOrganizationValue}
          label={t("createLetterDrawer.senders_area.organization")}
          defaultSelected={getOrganizationDefaultValue(recipientOrganizationId)}
          fetchData={fetchOrganizations}
          onFetchDataSuccess={setOrganizationOptions}
          onInputChange={handleOrganizationInputChange}
        />
      )}

      {!isLegalEntity && (
        <FormAutocomplete
          required
          freeSolo={false}
          inputValue={inputPersonalDataValue}
          label={t("createLetterDrawer.senders_area.individual")}
          defaultSelected={getPersonalDataDefaultValue(recipientPersonalDataId)}
          fetchData={fetchIndividuals}
          onFetchDataSuccess={setPersonalDataOptions}
          onInputChange={handlePersonalDataInputChange}
        />
      )}

      {isLegalEntity && (
        <FormAutocomplete
          required
          disabled={!recipientOrganizationId}
          freeSolo={false}
          inputValue={inputEmployeeValue}
          label={t("createLetterDrawer.senders_area.fullName")}
          defaultSelected={getEmployeeDefaultValue(
            isEmployeeSelection
              ? recipientEmployeeId
              : externalOrganizationStaffId
          )}
          fetchData={fetchEmployees}
          onFetchDataSuccess={setEmployeeOptions}
          onInputChange={handleEmployeeInputChange}
        />
      )}

      {isLegalEntity && (
        <FormSelect
          disabled
          label={t("createLetterDrawer.senders_area.position")}
          name={`recipients[${position}].recipientEmployeePosition`}
          control={control}
          values={senderPositionOptions}
        />
      )}
    </Stack>
  );
};
