import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetPotentialExecutors } from "api";
import { useAppSelector } from "app";
import { Modal } from "ui-kit";

import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { hexToRgba } from "@sbm/fe-utils";
import {
  IExecutorOption,
  ISubordinateOption,
  StructuralUnitTypesEnum,
  SubordinationType,
} from "@types";

export const TaskEmployeePopup = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { t } = useTranslation("tasks");
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState<ISubordinateOption>({});
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const { data = [] } = useGetPotentialExecutors();
  const { userProfile } = useAppSelector((state) => state.auth);

  const myEmployeeId = useMemo(() => {
    if (userProfile?.personalData) {
      return userProfile.personalData?.employees?.filter((i) => {
        if (i.validity) {
          return i.id;
        }
      });
    }
    return null;
  }, [userProfile]);

  const allSubordinates = useMemo(
    () =>
      data.filter(
        (d) =>
          d.subordinationType !== SubordinationType.notSubordinate ||
          d.employeeId === myEmployeeId?.[0].id
      ),
    [data, myEmployeeId]
  );

  const allSubordinatesOptions = allSubordinates.map((i) => ({
    title: i.personalData.fullName,
    value: i.id,
    key: i.id,
    uniqueId: i.employeeId,
    id: i.personalData.id,
    position: i.position,
    externalSubstitutionId: i.externalSubstitutionId,
    authorEmployeeId: i.author.employeeId,
    organizationId: i.organization.id,
    organizationName: i.organization.name,
    structuralUnitName: i.structuralUnit.name,
    structuralUnitType: i.structuralUnit.type,
  }));

  const handleSelectChange = (
    value: ISubordinateOption | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === "clear" || !value) {
      setSelectedOption({});
      setEmployeeId(null);
      return;
    }
    setSelectedOption(value);
    setEmployeeId(Number(value.uniqueId));
  };
  const haveSameOrganizationName = (array: IExecutorOption[]) => {
    if (array.length === 0) {
      return false;
    }
    const firstOrganizationName = array[0].organizationName;

    for (let i = 1; i < array.length; i++) {
      if (array[i].organizationName !== firstOrganizationName) {
        return false;
      }
    }
    return true;
  };

  const isSameOrganizationName = haveSameOrganizationName(
    allSubordinatesOptions
  );

  const handleConfirm = () => {
    window.open(`/subordinate/tasks/${employeeId}`, "_blank");
    onClose();
  };

  const content = {
    title: t("select_employee"),
    body: (
      <Autocomplete
        options={allSubordinatesOptions}
        value={selectedOption}
        onChange={(_, newValue, reason) => handleSelectChange(newValue, reason)}
        getOptionLabel={(option) => option.title || ""}
        sx={{ mb: 6 }}
        renderOption={(props, option) => (
          <li {...props} key={props.id}>
            <Stack
              flexDirection="column"
              justifyContent="center"
              gap={1}
              marginLeft={8}
            >
              <Typography
                color="text.primary"
                variant="subtitle1"
                sx={{ lineHeight: "16px" }}
              >
                {option.title}
              </Typography>
              <Typography
                variant="subtitle2"
                color={hexToRgba(theme.palette.text.primary, 0.7)}
                sx={{ lineHeight: "16px" }}
              >
                {option.position}
              </Typography>
              {option.structuralUnitType ===
                StructuralUnitTypesEnum.mainGoverningBody ||
              option.structuralUnitType ===
                StructuralUnitTypesEnum.goveringBody ? null : (
                <Typography
                  variant="subtitle2"
                  color={hexToRgba(theme.palette.text.primary, 0.7)}
                  sx={{ lineHeight: "16px" }}
                >
                  {option.structuralUnitName}
                </Typography>
              )}

              {!isSameOrganizationName ? (
                <Typography
                  variant="subtitle2"
                  color={hexToRgba(theme.palette.text.primary, 0.7)}
                  sx={{ lineHeight: "16px" }}
                >
                  {option.organizationName}
                </Typography>
              ) : null}
            </Stack>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            variant="outlined"
            color="secondary"
            size="medium"
            label={t("employees")}
          />
        )}
      />
    ),
    secondaryButton: (
      <Button
        variant="outlined"
        onClick={onClose}
        color="secondary"
        size="large"
      >
        {t("cancel")}
      </Button>
    ),
    mainButton: (
      <Button
        onClick={handleConfirm}
        disabled={!employeeId}
        variant="contained"
        color="secondary"
        size="large"
      >
        {t("confirm")}
      </Button>
    ),
  };

  return (
    <Modal updatedVersion isOpen={open} onClose={onClose} content={content} />
  );
};
