import toast from "react-hot-toast";

import i18next from "i18next";

import {
  getAPIErrorMessage,
  setStorageItem,
  STORAGE_KEYS,
} from "@sbm/fe-utils";

import { axiosService } from "../../services";
import { AppDispatch, globalSlice, setUserProfile } from "../index";

export const getCurrentUser = () => {
  return async (dispatch: AppDispatch) => {
    const { setLoading } = globalSlice.actions;

    dispatch(setLoading(true));

    try {
      const { data } = await axiosService({
        endpoint: "users/me",
      });

      dispatch(setUserProfile(data));

      if (data?.preferableLanguage) {
        setStorageItem(STORAGE_KEYS.lang, data.preferableLanguage);
        await i18next.changeLanguage(data.preferableLanguage);
      }
    } catch (error) {
      const errorMSG = getAPIErrorMessage(error);
      if (errorMSG) {
        toast.error(errorMSG);
      }
    }

    return dispatch(setLoading(false));
  };
};
