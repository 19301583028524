import { Paths } from "app";

import { TIconNames } from "@types";

export type TSidebarItem = {
  icon: TIconNames;
  name: string;
  route: string;
  collapse?: TSidebarItem[];
  withPermission?: boolean;
};

export const SIDEBAR_ITEMS: TSidebarItem[] = [
  {
    icon: "Home",
    name: "home",
    route: Paths.HOME,
  },
  {
    icon: "FolderOpen",
    name: "documents",
    route: Paths.DOCUMENTS,
    collapse: [
      {
        icon: "FileSignature",
        name: "contracts",
        route: Paths.CONTRACTS,
      },
      {
        icon: "Mail",
        name: "correspondence",
        route: Paths.CORRESPONDENCE,
      },
    ],
  },
  {
    icon: "Layers",
    name: "materials",
    route: Paths.MATERIALS,
    collapse: [],
  },
  {
    icon: "Building2",
    name: "organizations",
    route: Paths.ORGANIZATIONS,
  },
  {
    icon: "LibraryBigIcon",
    name: "projects",
    route: Paths.PROJECTS,
  },
  {
    icon: "Settings",
    name: "settings",
    route: Paths.SETTINGS,
    collapse: [
      {
        icon: "Users",
        name: "users",
        route: Paths.USERS,
      },
      {
        icon: "ShieldX",
        name: "sidebar.permissions",
        route: Paths.ACCESS_MANAGEMENT,
      },
      {
        icon: "Route",
        name: "sidebar.routes",
        route: Paths.APPROVAL_ROUTES,
      },
      {
        icon: "MessageCircle",
        name: "sidebar.support",
        route: Paths.TECH_SUPPORT,
      },
    ],
  },
];
