import { initReactI18next } from "react-i18next";

import i18n from "i18next";

import { getStorageItem, STORAGE_KEYS } from "@sbm/fe-utils";

// ENGLISH
import attachmentsEN from "../assets/locales/en/attachments.json";
import commonEN from "../assets/locales/en/common.json";
import correspondenceEN from "../assets/locales/en/correspondence.json";
import materialsEN from "../assets/locales/en/materials.json";
import projectEN from "../assets/locales/en/projects.json";
import tasksEN from "../assets/locales/en/tasks.json";
// RUSSIAN
import attachmentsRU from "../assets/locales/ru/attachments.json";
import commonRU from "../assets/locales/ru/common.json";
import correspondenceRU from "../assets/locales/ru/correspondence.json";
import materialsRU from "../assets/locales/ru/materials.json";
import projectRU from "../assets/locales/ru/projects.json";
import tasksRU from "../assets/locales/ru/tasks.json";

const resources = {
  en: {
    common: commonEN,
    attachments: attachmentsEN,
    tasks: tasksEN,
    correspondence: correspondenceEN,
    materials: materialsEN,
    project: projectEN,
  },
  ru: {
    common: commonRU,
    attachments: attachmentsRU,
    tasks: tasksRU,
    correspondence: correspondenceRU,
    materials: materialsRU,
    project: projectRU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getStorageItem(STORAGE_KEYS.lang) || "ru",
  defaultNS: "common",
  keySeparator: ".",
  returnNull: false,
  interpolation: {
    escapeValue: false,
  },
});
