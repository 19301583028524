import { TExecutionTrackerNode } from "@types";

const processNode = (
  node: TExecutionTrackerNode,
  parentId: string | number = ""
): TExecutionTrackerNode[] => {
  let result: TExecutionTrackerNode[] = [];

  if (node.titleForChildEvent) {
    const newNode: TExecutionTrackerNode = {
      ...node,
      parentId: parentId || "",
    };
    result.push(newNode);
  }

  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];

    result = [...result, ...processNode(child, node.id)];
  }

  return result;
};

export const getFormattedData = (
  nodes?: TExecutionTrackerNode[]
): TExecutionTrackerNode[] => {
  if (!nodes) return [];
  let result: TExecutionTrackerNode[] = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    result = [...result, ...processNode(node, i === 0 ? "" : node.id)];
  }

  return result;
};

export const getTruncatedStatus = (status: string) => {
  return status.length > 13 ? status.substring(0, 13) + "..." : status;
};
