import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "app";
import {
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { TableProps } from "../../types";
import { formatRange } from "./helpers";

export const SelectDate: React.FC<Pick<TableProps, "filterableFields">> = ({
  filterableFields,
}) => {
  const { t } = useTranslation();
  const { get, remove, append } = useQueryParams();
  const documentsFor = filterableFields?.find(
    (item) => item.optionType === "documentsFor"
  );

  const today = useMemo(() => new Date(), []);

  const dateRanges: Record<string, string> = useMemo(
    () => ({
      today: formatRange(today, today),
      week: formatRange(
        startOfWeek(today, { weekStartsOn: 1 }),
        endOfWeek(today, { weekStartsOn: 1 })
      ),
      month: formatRange(startOfMonth(today), endOfMonth(today)),
      threeMonths: formatRange(
        startOfMonth(addMonths(today, -2)),
        endOfMonth(today)
      ),
      sixMonths: formatRange(
        startOfMonth(addMonths(today, -5)),
        endOfMonth(today)
      ),
      year: formatRange(startOfMonth(addMonths(today, -11)), endOfMonth(today)),
      all: formatRange(new Date(2022, 0, 1), today),
    }),
    [today]
  );

  const selectOptions = [
    { value: "today", option: t("documents_for.today") },
    { value: "week", option: t("documents_for.week") },
    { value: "month", option: t("documents_for.month") },
    { value: "threeMonths", option: t("documents_for.three_months") },
    { value: "sixMonths", option: t("documents_for.six_months") },
    { value: "year", option: t("documents_for.year") },
    { value: "all", option: t("documents_for.all") },
  ];

  const [selectValue, setSelectValue] = React.useState<string>("threeMonths");

  useEffect(() => {
    if (!documentsFor) return;

    const filterQueriesFromUrl = get("filter", true) as string[];
    const currentFilter = filterQueriesFromUrl.find((query) =>
      query.includes(documentsFor.field || "")
    );

    if (currentFilter) {
      const [, range] = currentFilter.split("=");
      const selectedKey = Object.keys(dateRanges).find(
        (key) => dateRanges[key] === range
      );
      if (selectedKey) {
        setSelectValue(selectedKey);
      }
    }
  }, [documentsFor, get, dateRanges]);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setSelectValue(newValue);

    const date = dateRanges[newValue];

    if (!documentsFor) return;

    const filterQueriesFromUrl = get("filter", true) as string[];
    const formattedQueries = filterQueriesFromUrl.filter(
      (i) => !i.includes(documentsFor?.field || "")
    );

    const newFilterQueryToSet = [
      ...formattedQueries,
      `${documentsFor?.field}=${date}`,
    ];

    remove("filter");
    newFilterQueryToSet.forEach((query) => {
      append("filter", query);
    });
  };

  return (
    <Box sx={{ width: "200px" }}>
      <FormControl fullWidth>
        <InputLabel>{t("documents_for.label")}</InputLabel>
        <Select
          value={selectValue}
          label={t("documents_for.label")}
          onChange={handleChange}
          size="small"
          color="secondary"
          variant="outlined"
        >
          {selectOptions.map(({ value, option }, index) => (
            <MenuItem key={index} value={value}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
