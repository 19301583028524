import styled from "styled-components";
import { FLEX_COLUMN, FLEX_ROW_ALIGN_CENTER } from "ui-kit";

import { Card, CardContent } from "@mui/material";

export const Wrapper = styled.div`
  ${FLEX_COLUMN};
  height: calc(
    100vh - 64px - 48px - 58px - 74px - 42px - 24px
  ); // all the elements above cards

  overflow: hidden;

  background-color: ${({ theme }) => theme.palette.primary.contrastText};

  border-radius: 16px;
`;

export const Header = styled.div<{ $bgColor: string }>`
  ${FLEX_ROW_ALIGN_CENTER};
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};

  background-color: ${({ $bgColor }) => $bgColor};

  padding: ${({ theme }) => theme.spacing(4)};
`;

export const Content = styled.div<{ $isFavorites?: boolean }>`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(6)};
  flex: 1;

  overflow-y: auto;
  scroll-behavior: smooth;

  background-color: ${({ theme, $isFavorites }) =>
    $isFavorites
      ? theme.palette.primary.main
      : theme.palette.primary.contrastText};

  padding: ${({ theme }) => theme.spacing(4)};
`;

export const CardWrapper = styled(Card)`
  min-height: 190px;
  max-height: 190px;

  box-shadow: ${({ theme }) => theme.shadows[4]}!important;
`;

export const CardContentWrapper = styled(CardContent)`
  ${FLEX_COLUMN};
  gap: ${({ theme }) => theme.spacing(2)};

  .text-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const VectorWrapper = styled.div`
  width: 100%;
  max-width: 42px;
`;
