import styled from "styled-components";

import { alpha, ToggleButton } from "@mui/material";

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(6, 0)};
`;

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(3),
  width: "48px",
  height: "48px",

  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
  },

  "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderBottom: `1px solid ${theme.palette.divider} !important`,
  },
  "&.Mui-selected": {
    backgroundColor: `${alpha(theme.palette.secondary.main, 0.08)}`,
  },
}));
