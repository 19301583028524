import React from "react";
import { useTranslation } from "react-i18next";

import { useAddedCorrespondenceToMyDocument } from "api";
import { useAppDispatch } from "app";
import { Icon } from "ui-kit";

import {
  ListItemIcon,
  useTheme,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

interface Props {
  documentId: number;
  hadAddedDocument: boolean;
}

export const CorrespondenceTableActions: React.FC<Props> = ({
  documentId,
  hadAddedDocument,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation("correspondence");

  const { mutate: addMyDocument } = useAddedCorrespondenceToMyDocument();

  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    addMyDocument(documentId);

    handleClose();
  };

  return (
    <div>
      <Icon
        onClick={handlePopoverOpen}
        name="MoreVerticalIcon"
        color={theme.palette.primary.main}
      />

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "20ch",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem
          key="edit"
          onClick={handleClick}
          disabled={hadAddedDocument}
          sx={{ px: 4, py: 3 }}
        >
          <ListItemIcon>
            <Icon name="Pencil" color={theme.palette.primary.main} />
          </ListItemIcon>
          <Typography variant="body1">{t("add_my_documents")}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
