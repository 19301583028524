import React, { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getExternalOrganizationStaff } from "api";
import { axiosService } from "app";

import { Card, CardContent, Typography } from "@mui/material";
import { getPersonalDataName } from "@sbm/fe-utils";
import { FormAutocomplete, FormRadio, FormSelect } from "@sbm/ui-components";
import {
  IEmployee,
  IOrganization,
  IPersonalData,
  LegalEntityEnum,
  StructuralUnitTypesEnum,
} from "@types";

import { DetailsStepProps } from "../DetailsStep";

export const DetailsStepSender: React.FC<
  Pick<DetailsStepProps, "control" | "setValue" | "register" | "errors"> & {
    isEditMode: boolean;
  }
> = (props) => {
  const { control, setValue, isEditMode } = props;

  const { t } = useTranslation("correspondence");
  const {
    legalEntity,
    senderOrganizationId,
    personalDataId,
    externalOrganizationStaffId,
    senderEmployeeId,
  } = useWatch({
    control,
  });

  const isLegalEntity =
    typeof legalEntity === "string" ? legalEntity === "true" : legalEntity;

  const [inputOrganizationValue, setInputOrganizationValue] =
    React.useState("");
  const [inputPersonalDataValue, setInputPersonalDataValue] =
    React.useState("");
  const [inputEmployeeValue, setInputEmployeeValue] = React.useState("");

  const [organizationOptions, setOrganizationOptions] = React.useState<
    { title: string; id?: number; belongingToTheEvGroup: boolean }[]
  >([]);
  const [personalDataOptions, setPersonalDataOptions] = React.useState<
    { title: string; id?: number }[]
  >([]);
  const [employeeOptions, setEmployeeOptions] = React.useState<
    { title: string; id?: number; position: "string" }[]
  >([]);

  const selectedOrganization = organizationOptions.find(
    (i) => i.id === senderOrganizationId
  );

  const isEmployeeSelection = Boolean(
    selectedOrganization?.belongingToTheEvGroup
  );

  const legalEntityOptions = useMemo(
    () =>
      Object.values(LegalEntityEnum).map((option) => ({
        option: t(option),
        value: option === LegalEntityEnum.legalEntity,
      })),
    [t]
  );

  const senderPositionOptions = useMemo(() => {
    if (isLegalEntity && employeeOptions?.length > 0) {
      return employeeOptions.map((option) => {
        return {
          option: option.position,
          value: option.position,
        };
      });
    }

    return [];
  }, [employeeOptions, isLegalEntity]);

  // Default values
  const getOrganizationDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !organizationOptions.length) return "";
      const organization = organizationOptions.find((i) => i.id === id);
      if (!organization) return "";
      return organization.title;
    },
    [organizationOptions]
  );

  const getPersonalDataDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !personalDataOptions.length) return "";

      const option = personalDataOptions.find((i) => i.id === id);
      if (!option) return "";
      return option.title;
    },
    [personalDataOptions]
  );

  const getEmployeeDefaultValue = React.useCallback(
    (id?: number) => {
      if (!id || !employeeOptions.length) return "";

      const option = employeeOptions.find((i) => i.id === id);
      if (!option) return "";
      return option.title;
    },
    [employeeOptions]
  );

  // Fetch requests
  const fetchIndividuals = React.useCallback(
    async (search: string) => {
      const { data } = await axiosService({
        endpoint: `personal-data`,
        body: { search },
      });

      const options = data
        ? (data.items as IPersonalData[])
            .map((i) => ({
              title: getPersonalDataName(i),
              id: i.id,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (isEditMode && personalDataId) {
        const isInDefaultGetList = !options.find(
          (r) => r.id !== personalDataId
        );

        if (!isInDefaultGetList) {
          const { data: personalData } = await axiosService({
            endpoint: `personal-data/${personalDataId}`,
          });

          if (personalData) {
            options.push({
              title: getPersonalDataName(personalData),
              id: personalData.id,
            });
          }
        }
      }

      return options;
    },
    [isEditMode, personalDataId]
  );

  const fetchEmployees = React.useCallback(
    async (search: string) => {
      if (!senderOrganizationId) return [];

      // Case Selected organization is not in holding
      if (!isEmployeeSelection) {
        const data = await getExternalOrganizationStaff(
          search,
          senderOrganizationId
        );

        return data
          ? data.items
              .map((i) => ({
                title: getPersonalDataName(i.personalData, true),
                id: i.id,
                position: i.positionExternalOrganization || "",
              }))
              .filter((i) => Boolean(i.title))
          : [];
      }

      // Case Selected Organization in holding
      const { data } = await axiosService({
        endpoint: `employees`,
        body: {
          search,
          "filter.organizationId": senderOrganizationId,
          "filter.structuralUnitType": [
            StructuralUnitTypesEnum.mainGoverningBody,
            StructuralUnitTypesEnum.goveringBody,
          ],
        },
      });

      const result = data
        ? (data.items as IEmployee[])
            .map((i) => ({
              title: getPersonalDataName(i.personalData, true),
              id: i.id,
              position: i.staffUnit?.position?.namePosition || "",
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (isEditMode && senderEmployeeId) {
        const isInDefaultGetList = !result.find(
          (r) => r.id !== senderEmployeeId
        );

        if (!isInDefaultGetList) {
          const { data: employee } = await axiosService({
            endpoint: `employees/${senderEmployeeId}`,
          });

          if (employee) {
            result.push({
              title: getPersonalDataName(employee.personalData, true),
              id: employee.id,
              position: employee.staffUnit.namePosition,
            });
          }
        }
      }

      return result;
    },
    [isEmployeeSelection, senderOrganizationId, isEditMode, senderEmployeeId]
  );

  const fetchOrganizations = React.useCallback(
    async (search: string) => {
      const { data } = await axiosService({
        endpoint: `organizations`,
        body: { search },
      });

      const options = data
        ? (data.items as IOrganization[])
            .map((i) => ({
              title: i.briefTextOrganizationName || i.shortName,
              id: i.id,
              belongingToTheEvGroup: i.belongingToTheEvGroup,
            }))
            .filter((i) => Boolean(i.title))
        : [];

      if (senderOrganizationId && isEditMode) {
        const exists = options.findIndex((i) => i.id === senderOrganizationId);

        if (exists === -1) {
          const { data: organization } = await axiosService({
            endpoint: `organizations/${senderOrganizationId}`,
          });

          const optionToAdd = {
            title: organization.briefTextOrganizationName,
            id: organization.id,
            belongingToTheEvGroup: organization.inHolding,
          };

          options.push(optionToAdd);
        }
      }
      return options || [];
    },
    [senderOrganizationId, isEditMode]
  );

  // Change listeners
  const handleChangeListener = () => {
    setInputPersonalDataValue("");
    setInputOrganizationValue("");
    setInputEmployeeValue("");
    setValue("personalDataId", undefined, { shouldDirty: true });
    setValue("senderEmployeeId", undefined, { shouldDirty: true });
    setValue("senderOrganizationId", undefined, { shouldDirty: true });
    setValue("senderEmployeePosition", undefined, { shouldDirty: true });
    setValue("externalOrganizationStaffId", undefined, { shouldDirty: true });
  };

  const handleEmployeeInputChange = (val: string, reason: string) => {
    setInputEmployeeValue(val);

    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue("senderEmployeeId", undefined);
      setValue("senderEmployeePosition", undefined);
      setValue("externalOrganizationStaffId", undefined, { shouldDirty: true });
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = employeeOptions.find((i) => i.title === val);
      if (!option) return;
      setValue("personalDataId", undefined);
      setValue("senderEmployeePosition", option.position);

      const fieldToChange = isEmployeeSelection
        ? "senderEmployeeId"
        : "externalOrganizationStaffId";

      setValue(fieldToChange, option.id, { shouldDirty: true });

      if (fieldToChange === "senderEmployeeId") {
        setValue("externalOrganizationStaffId", undefined, {
          shouldDirty: true,
        });
      } else {
        setValue("senderEmployeeId", undefined, { shouldDirty: true });
      }
    }
  };

  const handleOrganizationInputChange = (val: string, reason: string) => {
    setInputOrganizationValue(val);

    if (reason === "input") return "";
    // Delete action
    if (reason === "clear") {
      setValue("personalDataId", undefined);
      setValue("senderOrganizationId", undefined);
      setValue("senderEmployeeId", undefined);
      setValue("externalOrganizationStaffId", undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = organizationOptions.find((i) => i.title === val);
      if (!option) return;

      setValue("senderOrganizationId", option.id);
    }
  };

  const handlePersonalDataInputChange = (val: string, reason: string) => {
    setInputPersonalDataValue(val);
    if (reason === "input") return;
    // Delete action
    if (reason === "clear") {
      setValue("personalDataId", undefined);
      return;
    }
    // Action is select from the list, should be stored under <personalDataId>
    if (reason === "reset") {
      const option = personalDataOptions.find((i) => i.title === val);
      if (!option) return;
      setValue("personalDataId", option.id);
      setValue("senderEmployeeId", undefined);
      setValue("externalOrganizationStaffId", undefined);
    }
  };

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.senders_area.title").toUpperCase()}
        </Typography>

        <FormRadio
          name="legalEntity"
          control={control}
          values={legalEntityOptions}
          onChangeListener={handleChangeListener}
        />

        {isLegalEntity && (
          <FormAutocomplete
            required
            freeSolo={false}
            inputValue={inputOrganizationValue}
            label={t("createLetterDrawer.senders_area.organization")}
            defaultSelected={getOrganizationDefaultValue(senderOrganizationId)}
            fetchData={fetchOrganizations}
            onFetchDataSuccess={setOrganizationOptions}
            onInputChange={handleOrganizationInputChange}
          />
        )}

        {!isLegalEntity && (
          <FormAutocomplete
            required
            freeSolo={false}
            inputValue={inputPersonalDataValue}
            label={t("createLetterDrawer.senders_area.individual")}
            defaultSelected={getPersonalDataDefaultValue(personalDataId)}
            fetchData={fetchIndividuals}
            onFetchDataSuccess={setPersonalDataOptions}
            onInputChange={handlePersonalDataInputChange}
          />
        )}

        {isLegalEntity && (
          <FormAutocomplete
            required
            disabled={!senderOrganizationId}
            freeSolo={false}
            inputValue={inputEmployeeValue}
            label={t("createLetterDrawer.senders_area.fullName")}
            defaultSelected={getEmployeeDefaultValue(
              isEmployeeSelection
                ? senderEmployeeId
                : externalOrganizationStaffId
            )}
            fetchData={fetchEmployees}
            onFetchDataSuccess={setEmployeeOptions}
            onInputChange={handleEmployeeInputChange}
          />
        )}

        {isLegalEntity && (
          <FormSelect
            disabled
            label={t("createLetterDrawer.senders_area.position")}
            name="senderEmployeePosition"
            control={control}
            values={senderPositionOptions}
          />
        )}
      </CardContent>
    </Card>
  );
};
