import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "app";

import {
  Chip,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { getLocaleDateFormat } from "@sbm/fe-utils";

import { Icon } from "../../../Icon";
import { dateFormat } from "../../helpers";
import { IFilterOption } from "../../types";
import { CustomFilters } from "../CustomFilters";
import { formatDateFiltersQuery } from "../FilterRangePicker/helpers";
import { FilterButtonContainer } from "./styles";

interface FilterButtonProps {
  title: string;
  value: string;
  field: string;
  onClear: (field: string) => void;
  onOpen: (target: HTMLDivElement | null, field: string) => void;
  anchorEl: HTMLDivElement | null;
  filterableFields: IFilterOption[];
  activeFilter: string;
}

export const FilterButton: React.FC<FilterButtonProps> = ({
  title = "",
  value = "",
  field,
  onClear,
  onOpen,
  anchorEl,
  filterableFields,
  activeFilter,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { get, append, remove } = useQueryParams();

  const ref = useRef<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl) && activeFilter === field;
  const documentsFor = filterableFields.find(
    (item) => item.optionType === "documentsFor"
  );
  const isDocumentsForAvailable = field === documentsFor?.field;

  const handleOpenPopover = () => onOpen(ref.current, field);
  const handleClosePopover = () => onOpen(null, "");

  const handleFiltersApply = (filters: string | string[]) => {
    let filterValueToSet = "";

    if (Array.isArray(filters)) {
      filters.forEach((filter, index) => {
        const valueToConcat = index > 0 ? `,${filter}` : filter;
        filterValueToSet = filterValueToSet.concat(valueToConcat);
      });
    } else {
      filterValueToSet = filters;
    }

    const filterQueriesFromUrl = get("filter", true) as string[];
    const formattedQueries = filterQueriesFromUrl.filter(
      (i) => !i.includes(field)
    );

    const newFilterQueryToSet = [
      ...formattedQueries,
      `${field}=${filterValueToSet}`,
    ];

    remove("filter");
    newFilterQueryToSet.forEach((query) => {
      append("filter", query);
    });
  };

  const filterField = filterableFields?.find((i) => i.field === field);

  const ValueContent = useMemo(() => {
    const splitValues = value.split(",").filter((i) => Boolean(i));
    const remainingItemsLength = splitValues.length - 1;
    const tooltipText = splitValues.length > 1 ? splitValues.join(", ") : "";

    let valueToSet = splitValues[0];

    const isDateQuery = splitValues[0]?.includes(" | ");

    if (isDateQuery) {
      const {
        isTodaySelected,
        isThisWeekSelected,
        isThisMonthSelected,
        startDate,
        endDate,
      } = formatDateFiltersQuery(splitValues[0], dateFormat);

      switch (true) {
        case isTodaySelected:
          valueToSet = t("today");
          break;
        case isThisWeekSelected:
          valueToSet = t("this.week");
          break;
        case isThisMonthSelected:
          valueToSet = t("this.month");
          break;
        default:
          valueToSet = `${getLocaleDateFormat(
            startDate
          )} - ${getLocaleDateFormat(endDate)}`;
      }
    }

    return (
      <Tooltip arrow color="secondary" placement="top" title={tooltipText}>
        <Typography
          variant="caption"
          color="secondary"
          sx={{ whiteSpace: "nowrap", ml: 1 }}
        >
          {valueToSet}

          {!isDateQuery && remainingItemsLength > 0 ? (
            <Chip
              size="small"
              color="secondary"
              label={`+${remainingItemsLength}`}
              sx={{ ml: 1 }}
            />
          ) : null}
        </Typography>
      </Tooltip>
    );
  }, [t, value]);

  if (isDocumentsForAvailable) return null;

  return (
    <>
      <FilterButtonContainer ref={ref} role="button">
        <Typography
          variant="caption"
          color="secondary"
          fontWeight={600}
          sx={{ whiteSpace: "nowrap" }}
        >
          {title}:
        </Typography>

        {ValueContent}

        <Stack flexDirection="row" alignItems="center" gap={1} sx={{ ml: 4 }}>
          <Icon
            name="X"
            color={theme.palette.secondary.main}
            size={16}
            onClick={() => onClear(field)}
          />
          <Icon
            name={open ? "ChevronUp" : "ChevronDown"}
            color={theme.palette.secondary.main}
            size={16}
            onClick={handleOpenPopover}
          />
        </Stack>
      </FilterButtonContainer>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              width: "fit-content",
              minWidth: 238,
            },
          }}
        >
          <CustomFilters
            filters={{
              field: filterField?.field,
              options: filterField?.options,
              optionType: filterField?.optionType,
              multiSelect: filterField?.multiSelect,
            }}
            onClose={handleClosePopover}
            onApply={handleFiltersApply}
          />
        </Popover>
      )}
    </>
  );
};
