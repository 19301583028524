export interface INotification {
  id: number;
  messageText: string;
  topic: string;
  messageDate: string | null;
  viewingDate: string | null;
  messageNumber: number;
  messageAuthor: {
    typeOfAuthor: "user" | "system";
    authorName?: string;
  };
  messageViewed: boolean;
  isImportant: boolean;
  additionalInformation: string | null;
}

export type TAutocompleteOption = {
  title: string;
  id: number;
};

export interface IFilterOption {
  title: string;
  value: string | string[];
  field?: string;
  options?: any[];
  optionType?: string;
  multiSelect?: boolean;
  type?: string;
  variant?: string;
}

export interface IMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
  unreadCount?: number;
  activeCount?: number;
  completedCount?: number;
  inTheSpotlightCount?: number;
  requiresActionCount?: number;
}

export interface IWorkingDay {
  id: number;
  date: string;
  workingDay: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum DrawerTypes {
  previewOrganizationStructerDrawer = "previewOrganizationStructerDrawer",
  messageDrawer = "messageDrawer",
  messageView = "message_view",
  createOrgDrawer = "createOrgDrawer",
  createLetterDrawer = "createLetterDrawer",
  createOrganizationStructerDrawer = "createOrganizationStructerDrawer",
  viewStaffUnitDrawer = "viewStaffUnitDrawer",
  staffAllocationDetails = "staffAllocationDetails",
}

export interface IAttachedDocumentLink {
  id: number;
  internalNumber: number;
  sequenceNumber: number;
  longNameOfTheDocument: string;
  createdAt: string;
  documentNumber: string | null;
  context: string | null;
  contextOfTheDocument: string | null;
  documentDate: string | null;
  documentName: string;
}

export enum MetricsEnum {
  TASK_WIDGET = "task_widget",
  MESSAGE_COUNT = "message_count",
  APPROVAL_COUNT = "approval_count",
  TASK_COUNT = "task_count",
}

export interface IMetrics {
  messageCount: number;
  approvalCount: number;
  taskCount: number;
  taskWidgetCounts: {
    incomingCompleted: number;
    incomingInTheSpotlight: number;
    incomingRequiresAction: number;
    incomingUnreadCount: number;
    outgoingCompleted: number;
    outgoingInTheSpotlight: number;
    outgoingRequiresAction: number;
    outgoingUnreadCount: number;
  };
}
