import styled from "styled-components";
import { FLEX_COLUMN_FULL } from "ui-kit";

import StaffUnitBackground from "../../../../../assets/backgrounds/staff_unit_background.png";

export const EllipsisTextWrapper = styled.div<{ $height?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer
    },
}
`;

export const PreviewImg = styled.div`
  ${FLEX_COLUMN_FULL};
  background-image: url(${StaffUnitBackground});
  background-position: left;
  width: 513px;
  height: 560px;
  background-repeat: no-repeat;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;
