import {
  IDocumentById,
  ServiceNoteEntity,
  TServiceNoteRecipientEntity,
} from "@types";

export const createRequestBody = (body: ServiceNoteEntity) => {
  const formData = new FormData();

  const keys = Object.keys(body) as (keyof ServiceNoteEntity)[];
  keys.forEach((key) => {
    if (
      key !== "files" &&
      key !== "links" &&
      key !== "constructionComplexes" &&
      key !== "typeOfAttachment" &&
      key !== "fileStatusAttachment" &&
      key !== "recipients"
    ) {
      formData.append(key, body[key] as string);
    }
  });

  if (body.files?.length) {
    body.files?.forEach((file) => {
      formData.append("files", file);
    });
  }

  if (body.links?.length) {
    body.links?.forEach((link, index) => {
      formData.append(`links[${index}]`, link.id.toString());
    });
  }

  if (body.typeOfAttachment?.length) {
    body.typeOfAttachment?.forEach((typeOfAttachment, index) => {
      formData.append(
        `typeOfAttachment[${index}]`,
        typeOfAttachment.toString()
      );
    });
  }
  if (body.fileStatusAttachment?.length) {
    body.fileStatusAttachment?.forEach((fileStatusAttachment, index) => {
      formData.append(
        `fileStatusAttachment[${index}]`,
        fileStatusAttachment.toString()
      );
    });
  }

  if (body.constructionComplexes?.length) {
    body.constructionComplexes?.forEach((complex, index) => {
      formData.append(`constructionComplexes[${index}]`, complex.toString());
    });
  }

  if (body.recipients?.length) {
    body.recipients?.forEach((recipient, index) => {
      if (recipient.recipientEmployeeId) {
        formData.append(
          `recipients[${index}][recipientEmployeeId]`,
          recipient.recipientEmployeeId.toString()
        );
      }
      if (recipient.recipientExternalSubstitutionId) {
        formData.append(
          `recipients[${index}][recipientExternalSubstitutionId]`,
          recipient.recipientExternalSubstitutionId.toString()
        );
      }
    });
  }

  return formData;
};

export const isNextButtonActive = (
  fields: ServiceNoteEntity,
  isEmployeeAndExternalSubInSameOrganization: boolean,
  textForTopicId?: number,
  topicForTheCorrespondence?: string,
  isEditMode?: boolean
) => {
  const REQUIRED_FIELDS: (keyof ServiceNoteEntity)[] = [
    "senderOrganizationId",
    "recipients",
  ];

  if (fields.replyToOutgoingLetter) {
    REQUIRED_FIELDS.push("initiatingCorrespondenceId");
  }

  if (isEmployeeAndExternalSubInSameOrganization && !isEditMode) {
    REQUIRED_FIELDS.push("creatorPosition");
  }

  if (!fields.senderExternalSubstitutionId) {
    REQUIRED_FIELDS.push("senderEmployeeId");
  }

  if (!textForTopicId && !topicForTheCorrespondence) {
    return false;
  }

  return REQUIRED_FIELDS.every((key) => {
    if (key === "recipients") {
      const isRecipientsFilled = fields[key].every((recipient) => {
        return (
          recipient.recipientEmployeeId ||
          recipient.recipientExternalSubstitutionId
        );
      });

      return isRecipientsFilled;
    }

    return fields[key] || fields[key] === false;
  });
};

export const getDefaultDataForEdit = (
  data?: IDocumentById
): ServiceNoteEntity | null => {
  if (!data) return null;

  return {
    id: data.id,
    correspondenceSubtype: data.correspondenceSubtype,
    initiatingCorrespondenceId: data.initiatingCorrespondenceId,
    initiatingDocumentOutTheSystem: data.initiatingDocumentOutTheSystem,
    initiatingDocumentRegistrationDate: data.initiatingCorrespondenceDate
      ? new Date(data.initiatingCorrespondenceDate)
      : undefined,
    expectedDateOfTheResponse: data.expectedDateOfTheResponse
      ? new Date(data.expectedDateOfTheResponse)
      : undefined,
    replyToOutgoingLetter: !!data.initiatingCorrespondenceId,
    confidentially: data.confidentially,
    selectConstructionComplexes: data.constructionComplexes.length > 0,
    constructionComplexes: data.constructionComplexes.map((i) => i.id),
    senderEmployeePosition: data.sender.position,
    senderEmployeeId: data.sender.employeeId,
    senderExternalSubstitutionId: data.sender.externalSubstitutionId,
    senderStructuralUnitId: data.sender.structuralUnit?.id,
    senderOrganizationId: data.sender.organizationId,
    textForTopicId: data.textForTopicId,
    controlTheResponse: data.controlTheResponse,
    topicForTheCorrespondence: data.topicForTheCorrespondence,
    contextForTheCorrespondence: data.contextForTheCorrespondence,
    recipients: (data.recipients.map((rec) => ({
      recipientEmployeeId: rec.employeeId,
      recipientExternalSubstitutionId: rec.externalSubstitutionId,
      recipientStructuralUnitId: rec.structuralUnit?.id,
      recipientEmployeePosition: rec.position,
    })) || []) as TServiceNoteRecipientEntity[],
  };
};
