import toast from "react-hot-toast";

import { getAPIErrorMessage, SnackbarMessages } from "@sbm/fe-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IUser, IUserRole } from "@types";

import {
  createUserMutation,
  getUserRoles,
  getUsers,
  updateUserPhoto,
} from "../../requests";

export const useCreateUser = (
  onSuccess: () => void,
  onError: (error: unknown) => void
) => {
  return useMutation(
    async (data: IUser) => {
      return await createUserMutation(data);
    },
    {
      onMutate: async () => {
        // if needed set loading or do something in process of the request
      },
      onSuccess: () => {
        onSuccess();
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useGetUsers = ({
  page,
  pageSize,
  filter,
  sortBy,
  search,
}: {
  page: number;
  pageSize: number;
  filter: string[];
  sortBy: string;
  search: string;
}) => {
  return useQuery(
    ["get_users", page + 1, pageSize, filter, sortBy, search],

    async () => {
      return getUsers({
        page,
        limit: pageSize,
        filter,
        sortBy,
        search,
      });
    }
  );
};

export const useGetUserRoles = (userId?: number) => {
  return useQuery<IUserRole[]>(["get_user_roles", userId], async () => {
    if (!userId) return [];
    return getUserRoles(userId);
  });
};

export const useUpdateUserPictureMutation = (onSuccess: () => void) => {
  return useMutation(
    async (formData: FormData) => {
      return await updateUserPhoto(formData);
    },
    {
      onSuccess: () => {
        toast.success(SnackbarMessages.success);
        onSuccess();
      },
      onError: (error) => {
        const errorMessage = getAPIErrorMessage(error);
        toast.error(errorMessage);
      },
    }
  );
};
