import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "ui-kit";

import { Grid, Typography, useTheme } from "@mui/material";
import { IAttachedDocumentLink, IAttachLink } from "@types";

import { EmptyLinksSectionWrapper, LinksItemWrapper, Wrapper } from "../styles";

interface Props {
  disabled?: boolean;
  additionalClass?: string;
  links: IAttachedDocumentLink | IAttachLink | null | undefined;
  onRemoveAttachedLink?: () => void;
}

export function UploadedLinksSection({
  disabled = false,
  additionalClass,
  links,
  onRemoveAttachedLink,
}: Props) {
  const theme = useTheme();
  const { t: tAttachment } = useTranslation("attachments");

  return (
    <Wrapper disabled={disabled}>
      <>
        <div>
          <div className={`uploaded_links_section ${additionalClass}`}>
            {links ? (
              <LinksItemWrapper key={links.id}>
                <Grid container>
                  <Grid item xs={1} className="link_delete_icon">
                    <Icon name="Link" color={theme.palette.secondary.main} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color="secondary.main"
                    >
                      {links.documentName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className="link_delete_icon">
                    <Icon
                      name="X"
                      color={theme.palette.secondary.main}
                      onClick={onRemoveAttachedLink}
                    />
                  </Grid>
                </Grid>
              </LinksItemWrapper>
            ) : (
              <>
                <EmptyLinksSectionWrapper />
                <div className="empty__text">
                  <Typography variant="body1" color="text.primary">
                    {tAttachment("noAttachedLinks")}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </Wrapper>
  );
}
