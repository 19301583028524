import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { setModal, useAppDispatch, useAppSelector } from "app";
import { Icon } from "ui-kit";

import {
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  Stack,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getLocaleDateFormat } from "@sbm/fe-utils";
import { ApproverActions, EnumApproverStatusType, ModalVariants } from "@types";

import { Wrapper, UpperSection, BottomSection } from "./styles";

interface ApproverSectionProps {
  rowData: any;
  isAgreed: boolean;
  isDraft: boolean;
  responsibleEmployeeId?: number;
  isResponsibleEmployeeCurrentUser?: boolean;
}

export const ApproverSection: React.FC<ApproverSectionProps> = ({
  rowData,
  isAgreed,
  isDraft,
  responsibleEmployeeId,
  isResponsibleEmployeeCurrentUser,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { userProfile } = useAppSelector((state) => state.auth);

  const personalId = userProfile?.id;
  const personalEmployeeIds =
    userProfile?.personalData?.employees?.map((emp) => emp.id) || [];

  const [infoOpen, setInfoOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openActions = Boolean(anchorEl);
  const isApproved = rowData.statusForAction === ApproverActions.approved;
  const isRejected = rowData.statusForAction === ApproverActions.rejected;
  const isUnderConsideration =
    rowData.statusForAction === ApproverActions.under_consideration;
  const isPending =
    !isApproved && !isRejected && !isUnderConsideration && !isDraft;

  const handleActionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (actionType: string) => {
    dispatch(
      setModal({
        open: true,
        variant: ModalVariants.contractApprovalActions,
        content: { data: { actionType, id: rowData.singleApprovalId } },
      })
    );

    setAnchorEl(null);
  };

  const toggleInfoOpen = () => setInfoOpen((prevState) => !prevState);

  const isDisabledActions = () => {
    if (
      (personalId === responsibleEmployeeId ||
        isResponsibleEmployeeCurrentUser) &&
      isRejected
    )
      return false;

    return !(personalId === rowData.userId && isUnderConsideration);
  };

  const getActions = () => {
    if (!rowData.statusForAction) return;

    switch (rowData.statusForAction.toLowerCase()) {
      case EnumApproverStatusType.approved:
      case EnumApproverStatusType.rejected:
        return [
          <MenuItem
            key={ApproverActions.returning}
            onClick={() => handleOpenModal(ApproverActions.returning)}
          >
            <ListItemIcon sx={{ mr: 5 }}>
              <Icon name="Redo" color={theme.palette.primary.main} />
            </ListItemIcon>
            <ListItemText primary={t("repeat.negotiation")} />
          </MenuItem>,
          <MenuItem
            key={ApproverActions.start}
            onClick={() => handleOpenModal(ApproverActions.start)}
          >
            <ListItemIcon sx={{ mr: 5 }}>
              <Icon name="IterationCcw" color={theme.palette.primary.main} />
            </ListItemIcon>
            <ListItemText primary={t("start.route.over")} />
          </MenuItem>,
        ];
      case EnumApproverStatusType.under_consideration:
        return [
          <MenuItem
            key={ApproverActions.approved}
            onClick={() => handleOpenModal(ApproverActions.approved)}
          >
            <ListItemIcon sx={{ mr: 5 }}>
              <Icon name="Check" color={theme.palette.success.main} />
            </ListItemIcon>
            <ListItemText primary={t("agree")} />
          </MenuItem>,
          <MenuItem
            key={ApproverActions.rejected}
            onClick={() => handleOpenModal(ApproverActions.rejected)}
          >
            <ListItemIcon sx={{ mr: 5 }}>
              <Icon name="X" color={theme.palette.error.main} />
            </ListItemIcon>
            <ListItemText primary={t("reject")} />
          </MenuItem>,
        ];
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <UpperSection $open={infoOpen}>
        <IconButton onClick={toggleInfoOpen} className="cursor-pointer">
          <Icon
            name={infoOpen ? "ChevronDown" : "ChevronRight"}
            size={20}
            color={theme.palette.primary.main}
            className="cursor-pointer"
          />
        </IconButton>

        <Stack className="ellipsis" sx={{ width: "100%" }}>
          <Tooltip arrow placement="top" title={rowData.longName}>
            <Typography variant="body1" fontWeight={600} className="ellipsis">
              {rowData.longName}
            </Typography>
          </Tooltip>

          <BottomSection $open={infoOpen}>
            <Tooltip arrow placement="top" title={rowData.position}>
              <Typography
                className="ellipsis"
                variant="body2"
                color="text.secondary"
              >
                {rowData.position}
              </Typography>
            </Tooltip>

            <Tooltip arrow placement="top" title={rowData.approverType}>
              <Typography
                component="p"
                variant="body4"
                fontWeight={700}
                color="text.disabled"
                className="ellipsis"
              >
                {rowData.approverType}
              </Typography>
            </Tooltip>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Tooltip
                arrow
                placement="top"
                title={
                  <Stack>
                    <Typography
                      variant="body4"
                      color={theme.palette.primary.contrastText}
                      fontWeight={700}
                      sx={{ mb: 1 }}
                    >
                      {t("approval.completion.date")}
                    </Typography>
                    {/* <Typography
                      variant="body4"
                      color={theme.palette.primary.contrastText}
                    >
                      {t("approval.planned.date", {
                        date: getLocaleDateFormat(
                          rowData.planDateOfEndingSingleApproval
                        ),
                      })}
                    </Typography>
                    <Typography
                      variant="body4"
                      color={theme.palette.primary.contrastText}
                    >
                      {t("approval.fact.date", {
                        date: getLocaleDateFormat(
                          rowData.factDateIterationEnding
                        ),
                      })}
                    </Typography> */}
                    {isPending && (
                      <Typography
                        variant="body4"
                        color={theme.palette.primary.contrastText}
                      >
                        {t("plan.duration", {
                          count: rowData.termSingleApproval,
                        })}
                      </Typography>
                    )}
                    {isUnderConsideration && (
                      <>
                        <Typography
                          variant="body4"
                          color={theme.palette.primary.contrastText}
                        >
                          {t("start.of.approval", {
                            date: getLocaleDateFormat(
                              rowData.dateOfEntrySingleApproval,
                              "hh:mm"
                            ),
                          })}
                        </Typography>
                        <Typography
                          variant="body4"
                          color={theme.palette.primary.contrastText}
                        >
                          {t("plan.date.of.ending.approval", {
                            date: getLocaleDateFormat(
                              rowData.planDateOfEndingSingleApproval,
                              "hh:mm"
                            ),
                          })}
                        </Typography>
                      </>
                    )}
                    {(isApproved || isRejected) && (
                      <>
                        <Typography
                          variant="body4"
                          color={theme.palette.primary.contrastText}
                        >
                          {t("approval.start.date", {
                            date: getLocaleDateFormat(
                              rowData.dateOfEntrySingleApproval,
                              "hh:mm"
                            ),
                          })}
                        </Typography>
                        <Typography
                          variant="body4"
                          color={theme.palette.primary.contrastText}
                        >
                          {t("approval.end.date", {
                            date: getLocaleDateFormat(
                              rowData.factDateIterationEnding,
                              "hh:mm"
                            ),
                          })}
                        </Typography>
                      </>
                    )}
                  </Stack>
                }
              >
                <IconButton sx={{ p: 0 }}>
                  <Icon
                    name="Calendar"
                    size={16}
                    color={theme.palette.primary.main}
                  />
                </IconButton>
              </Tooltip>

              {/* {!isAgreed && !isDraft && (
                <Tooltip arrow placement="top" title={rowData.comment}>
                  <IconButton sx={{ p: 0 }}>
                    <Icon
                      name="MessageSquare"
                      size={16}
                      color={theme.palette.secondary.main}
                    />
                  </IconButton>
                </Tooltip>
              )} */}
              {!isPending && !isUnderConsideration && (
                <Tooltip arrow placement="top" title={rowData.comment}>
                  <IconButton sx={{ p: 0 }}>
                    <Icon
                      name="MessageSquare"
                      size={16}
                      color={theme.palette.secondary.main}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </BottomSection>
        </Stack>

        {rowData.statusForAction ? (
          <>
            <IconButton
              onClick={handleActionsClick}
              disabled={isDisabledActions()}
              aria-haspopup="true"
            >
              <Icon
                name="MoreVertical"
                size={28}
                color={theme.palette.primary.main}
                disabled={isDisabledActions()}
              />
            </IconButton>

            <Menu
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={anchorEl}
              open={openActions}
              onClose={handleActionsClose}
              PaperProps={{
                sx: {
                  borderRadius: "16px",
                  py: 2,
                },
              }}
            >
              {getActions()}
            </Menu>
          </>
        ) : null}
      </UpperSection>
    </Wrapper>
  );
};
