import React, { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "app";
import {
  useAppSelector,
  getUserEmployeeOrganizationsAndPositions,
  getUserExternalSubstitutionOrganizationsAndPositions,
} from "app";

import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { FormRadio } from "@sbm/ui-components";

import { DetailsStepProps } from "../DetailsStep";

export const DetailsStepAdditionalInfo: React.FC<
  Pick<DetailsStepProps, "control" | "setValue" | "errors"> & {
    isEditMode?: boolean;
  }
> = ({ control, setValue, isEditMode = false }) => {
  const { palette } = useTheme();
  const { t } = useTranslation("correspondence");

  const { userProfile } = useAppSelector((state) => state.auth);
  const { senderOrganizationId } = useWatch({ control });

  const userEmployeeOrganizationsAndPositions =
    getUserEmployeeOrganizationsAndPositions(userProfile?.personalData);

  const userEmployeeOrganizationsAndPositionsIds =
    userEmployeeOrganizationsAndPositions.map((i) => i.id);

  const userExternalSubstitutionOrganizationsAndPositions =
    getUserExternalSubstitutionOrganizationsAndPositions(
      userProfile?.personalData
    );

  const userExternalSubstitutionOrganizationsAndPositionsIds =
    userExternalSubstitutionOrganizationsAndPositions.map((i) => i.id);

  const isEmployeeAndExternalSubInSameOrganization =
    senderOrganizationId &&
    userEmployeeOrganizationsAndPositionsIds.includes(senderOrganizationId) &&
    userExternalSubstitutionOrganizationsAndPositionsIds.includes(
      senderOrganizationId
    );

  const employeeAndExternalSubPositionOptions = React.useMemo(() => {
    if (!senderOrganizationId || !isEmployeeAndExternalSubInSameOrganization)
      return [];

    const employeePosition = userEmployeeOrganizationsAndPositions.find(
      (i) => i.id === senderOrganizationId
    )?.position;

    const externalPosition =
      userExternalSubstitutionOrganizationsAndPositions.find(
        (i) => i.id === senderOrganizationId
      )?.position;

    if (!externalPosition || !employeePosition) return [];

    // find repeated org id and show position for employee
    const employeeOption = {
      option: employeePosition,
      value: "employee",
    };
    // find repeated org id and show position for external sub
    const externalSubOption = {
      option: externalPosition,
      value: "external",
    };

    return [employeeOption, externalSubOption];
  }, [
    isEmployeeAndExternalSubInSameOrganization,
    senderOrganizationId,
    userEmployeeOrganizationsAndPositions,
    userExternalSubstitutionOrganizationsAndPositions,
  ]);

  useEffect(() => {
    setValue("creatorPosition", undefined);
  }, [senderOrganizationId, setValue]);

  if (!isEmployeeAndExternalSubInSameOrganization || isEditMode) return null;

  return (
    <Card>
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h9_semiBold" color="text.disabled" sx={{ mb: 2 }}>
          {t("createLetterDrawer.additional_info.title").toUpperCase()}
        </Typography>

        {employeeAndExternalSubPositionOptions.length > 0 && !isEditMode && (
          <Stack>
            <FormRadio
              required
              labelPropsSx={{
                fontWeight: 700,
                color: palette.primary.main,
                mb: 2,
              }}
              label={t(
                "createLetterDrawer.additional_info.position_checkbox_label"
              )}
              name="creatorPosition"
              control={control}
              values={employeeAndExternalSubPositionOptions}
            />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};
